import icons from "../../../config/icons"
import { LANDING_PAGE_TEXT } from "../constants"
import { getText } from "../../../helpers/translateHelper"
import { IPropsIntroductionComponent } from "../../../config/landingPageInterface"

const IntroductionComponent = ({ isTablet }: IPropsIntroductionComponent) => {
  const { WELCOME_TO, AIRTEL_LITE, GET_LINK_DESCRIPTION } = LANDING_PAGE_TEXT

  return (
    <div className={`airtel-lite-link-summaruy flex flex-col justify-center ${isTablet ? 'self-center' : ''}`}>
      <div className={`airtel-lite-link-summaruy flex flex-col justify-start ${isTablet ? 'items-center' : 'items-start'}`}>
        <div className="text-12 font-medium">
          {getText(WELCOME_TO)}
        </div>
        <div className="flex flex-row gap-15">
          <div className="text-32 font-bold">
            {getText(AIRTEL_LITE)}
          </div>
          <div>
            <img alt='airtel logo' src={icons.airtelLogo} className="rounded-md" height={'36px'} width={'36px'} />
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: getText(GET_LINK_DESCRIPTION) }} className="text-14" />
      </div>
    </div>
  )
}

export default IntroductionComponent