import React from "react"
interface IDividerProps {
    isVertical?: boolean
    style?: string
}
const Divider = ({ isVertical, style }: IDividerProps) => {
    return (<>
        {!isVertical ? <hr className={style ?? "hr w-full my-15 border-t border-[#E6E6E6]"} /> : null}
    </>)
}
export default React.memo(Divider)