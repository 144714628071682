export const GLOBAL = {
  MANAGE_SECURITY_QUESTION_HEADING: "MANAGE_SECURITY_QUESTION_HEADING",
  TRUE_STR_TEXT: 'true',
  FUNCTION: "function",
  OBJECT: "object",
  BOOLEAN: "boolean",
  NUMBER: "number",
  STRING: "string",
  ASC: "asc",
  DESC: "desc",
  YES_TEXT_SHORT: "Y",
  INPUT: "INPUT",
  FOCUS_IN: "focusin",
  FOCUS_OUT: "focusout",
  MOBILE_TEXT: "mobile",
  TABLET_TEXT: "tablet"
};
