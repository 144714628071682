import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Redux";
import { ITranslationStore, callFetchTranslations } from "../Redux/slices/translationSlice";
import { fallbackTranslationCount } from "../data/constants";
import { getAuthTokenFromLs, isMobileDevice } from "../utils/commonUtils";

/**
 * This hook fetches translations and returns 'loading' status of fetched translations.
 * Only fetches translations if they dont already exist or when logged out tries to refresh them.
 */
export const useTranslations = () => {
  const [translationsAreLoading, setTranslationsAreLoading] = useState<boolean>(false);
  const [translationsAreInit, setTranslationsAreInit] = useState<boolean | null>(null),
    [translationError, setTransactionError] = useState<boolean>(false);

  const translationStore = useSelector((state: RootState) => state?.translationStore);
  const userIsLoggedIn = getAuthTokenFromLs();

  const dispatch = useDispatch();
  const isHePage = window?.location?.pathname === '/' && isMobileDevice();

  useEffect(() => {
    const alreadyExists = checkAlreadyExists(translationStore);
    setTranslationsAreInit(alreadyExists);
    if (!alreadyExists && !isHePage) {
      fetchTranslations();
    }
    if (isHePage)
      setTranslationsAreInit(isHePage)

  }, [userIsLoggedIn, window.location.pathname])

  const fetchTranslations = () => dispatch(callFetchTranslations({
    progressCallback: () => setTranslationsAreLoading(true),
    successCallback: () => {
      setTranslationsAreInit(true);
      setTranslationsAreLoading(false);
      setTransactionError(false);
    },
    errorCallback: () => {
      setTranslationsAreInit(true);
      setTranslationsAreLoading(false);
      setTransactionError(true);
    },
    hideLoader: true
  }));

  return { translationsAreInit: !!translationsAreInit, translationsAreLoading, translationError };
}

/**
 * Check translation already exists.
 * Its considered initialized if it contains more translations than fallback.
 * @param translationStore 
 * @returns true if init, else false
 */
function checkAlreadyExists(translationStore: ITranslationStore): boolean {
  return translationStore?.count !== undefined && (translationStore?.count > fallbackTranslationCount);
}
