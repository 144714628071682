import { useEffect, useRef, useState } from "react";
import { ICounter } from "../../features/MPESA/interfaces/MPESAInterfaces";

//Counter component
const Timer = ({ className, defaultTimer, onTimeout }: ICounter) => {
    const [time, setTime] = useState(defaultTimer);
    const timeRef = useRef(time);
    timeRef.current = time;

    const timerID = useRef<NodeJS.Timer>();
    const timeObj = getTime(time);

    if (!time)
        onTimeout();

    useEffect(() => {
        if (!time) {
            clearInterval(timerID.current);
        }

        timerID.current = setInterval(() => {
            if (!timeRef.current)
                return;
            setTime(time => time - 1);
        }, 1000);

        return () => {
            clearInterval(timerID.current);
        }

    }, []);

    return <div className={`timer ${className}`}>
        {`${timeObj.min} : ${timeObj.sec}`}
    </div>
}

interface ITime {
    min: string,
    sec: string
}

const getTime = (time: number): ITime => {
    let minVal = Math.trunc(time / 60);
    let secVal = time % 60;
    let min = minVal < 10 ? `0${minVal}` : `${minVal}`;
    let sec = secVal < 10 ? `0${secVal}` : `${secVal}`
    return { min, sec };
}

export default Timer;