import { useEffect, useRef, useState } from "react";
import { CALL_SEND_OTP, CLOSE_SECURE_SMS, FAILED_STATE, INITIAL_STATE, LOCKED_STATE, OK, POLLING_STATE, SEND_OTP, SMS_ACCOUNT_LOCKED, SMS_INVALID_INPUT_COMBINATION_CODE, SMS_MAXIMUM_HIT_CODE, SMS_MAX_ATTEMPT_TEXT, SMS_MSISDN_MISMATCH_TEXT, SMS_MSISDN_VERIFICATION_ERROR_CODE, SMS_NOT_SEND_CODE, SMS_RECIVER_MSISDN_MISMATCH_CODE, SMS_SENDER_MSISDN_MISMATCH_CODE, SMS_SOME_ERROR_OCCURED_CODE, SMS_TOKEN_VERIFICATION_EXPIRED_CODE, SMS_USER_CHANGED_SMS_CONTENT, SMS_VERIFICATION_FAILED_TEXT, SUCCESS_STATE, TIMEOUT_STATE, VERIFICATION_SUCCESS_CODE } from "./constants";
import SecureSmsInitiatedModal from "./components/SecureSmsInitiatedModal";
import SecureSmsPollingModal from "./components/SecureSmsPollingModal";
import SecureSmsSuccessModal from "./components/SecureSmsSuccessModal";
import SecureSmsTimeoutModal from "./components/SecureSmsTimeoutModal";
import SecureSmsLockedModal from "./components/SecureSmsLockedModal";
import SecureSmsFailedModal from "./components/SecureSmsFailedModal";
import { useDispatch, useSelector } from "react-redux";
import { callVerifySSmsToken } from "../../Redux/slices/loginSlice";
import { addSpaceInMsisdn, getDeviceInfo } from "../../helpers/commonHelpers";
import usePolling from "../../hooks/usePolling";
import { getPhoneNumberFromLs, setAuthTokenToLs } from "../../utils/commonUtils";
import { RootState } from "../../Redux";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "../../routes/routeConstants";
import { fetchGSMBalances, sendOtp } from "../login/helpers/loginHelper";
import { getText } from "../../helpers/translateHelper";
import { sendGA4Event } from "../../services/ga4";
import "./components/style.scss";
interface ISecureSMS {
  secureToken: string;
  shortHand: string;
  closeSecureSms: (val: boolean) => void;
  sendSecureSmsToken: () => void;
  setApiErrorMsg: (val: any) => void;
  setDoShowOTPScreen: (val: any) => void;
  setOTPId: (val: any) => void;
  errorCode?: number | null;
}

const SecureSms = ({ secureToken, shortHand, closeSecureSms, sendSecureSmsToken, setApiErrorMsg, setDoShowOTPScreen, setOTPId, errorCode }: ISecureSMS) => {
  const [currState, setCurrState] = useState<string>(INITIAL_STATE),
    [failedMessageKey, setFailedMessageKey] = useState<string>(""),
    [failedCtaKey, setFailedCtaKey] = useState<string>(""),
    [failedCase, setFailedCase] = useState<string>(""),
    secureSmsTimeout = useSelector((state: RootState) => state.config.launchConfig.secureSmsTimeout),
    secureSmsDelay = useSelector((state: RootState) => state.config.launchConfig.secureSmsDelay),
    loanConfig = useSelector((state: RootState) => state.config?.launchConfig?.loanConfig),
    { startPolling, stopPolling, isTimeouted } = usePolling(verifySecureSmsToken, secureSmsDelay * 1000, secureSmsTimeout * 1000),
    countryCode = useSelector((state: RootState) => state.config?.launchConfig?.phoneCode),
    timeouted = useRef(false),
    dispatch = useDispatch(),
    deviceInfo = getDeviceInfo(),
    msisdn = getPhoneNumberFromLs(),
    navigate = useNavigate();

  useEffect(() => {
    sendGA4Event({ category: "secure-sms", action: "login_securesms_screenviewed" });
    if (errorCode === SMS_ACCOUNT_LOCKED)
      setCurrState(LOCKED_STATE);
  }, []);

  useEffect(() => {
    if (isTimeouted) {
      setCurrState(TIMEOUT_STATE);
      timeouted.current = true;
    }
  }, [isTimeouted]);

  function verifySecureSmsToken() {
    let payload = {
      data: {
        "msisdn": msisdn,
        "osystem": deviceInfo.OSName,
        "browser": deviceInfo.BrowserName
      },
      hideLoader: true,
      callback: function (response: any) {
        !timeouted.current && setDifferentStates(response);
      }
    };
    dispatch(callVerifySSmsToken(payload));
  }

  function startVerificationCall() {
    startPolling();
    setCurrState(POLLING_STATE);
    sendGA4Event({ category: "secure-sms", action: "login_securesms_proceedclicked" });
  }

  function resendSecureSmsToken() {
    sendSecureSmsToken();
    setCurrState(INITIAL_STATE);
    timeouted.current = false;
  }

  function setDifferentStates(data: any = {}) {
    let state = "";
    switch (data.statusCode) {
      case VERIFICATION_SUCCESS_CODE:
        state = SUCCESS_STATE;
        closeAfterSuccess(data);
        sendGA4Event({ category: "secure-sms", action: "login_securesms_smsreceived" });
        break;
      case SMS_TOKEN_VERIFICATION_EXPIRED_CODE:
        state = TIMEOUT_STATE;
        stopPolling();
        sendGA4Event({ category: "secure-sms", action: "login_securesms_smscheck_fail_timedout" });
        break;
      case SMS_SENDER_MSISDN_MISMATCH_CODE:
      case SMS_RECIVER_MSISDN_MISMATCH_CODE:
      case SMS_INVALID_INPUT_COMBINATION_CODE:
        state = FAILED_STATE;
        getFailedMessage(SMS_MSISDN_MISMATCH_TEXT, getPhoneNumberFromLs());
        setFailedCtaKey(OK);
        setFailedCase(CLOSE_SECURE_SMS);
        stopPolling();
        sendGA4Event({ category: "secure-sms", action: "login_securesms_smscheck_fail_msisdn_mismatch" });
        break;
      case SMS_MAXIMUM_HIT_CODE:
      case SMS_ACCOUNT_LOCKED:
        state = LOCKED_STATE;
        stopPolling();
        sendGA4Event({ category: "secure-sms", action: "login_securesms_smscheck_fail_locked" });
        break;
      case SMS_MSISDN_VERIFICATION_ERROR_CODE:
      case SMS_SOME_ERROR_OCCURED_CODE:
      case SMS_USER_CHANGED_SMS_CONTENT:
        state = FAILED_STATE;
        getFailedMessage(SMS_VERIFICATION_FAILED_TEXT);
        setFailedCtaKey(OK);
        setFailedCase(CLOSE_SECURE_SMS);
        stopPolling();
        sendGA4Event({ category: "secure-sms", action: "login_securesms_smscheck_fail_message_edited" });
        break;
      default:
        state = handleDefaultState();
        sendGA4Event({ category: "secure-sms", action: "login_securesms_smscheck_fail_message_edited" });
        //  state = timeouted.current ? TIMEOUT_STATE : currState;
        break;
    }
    setCurrState(state);
  }

  function closeAfterSuccess(data: any = {}) {
    let timeId = setTimeout(() => {
      closeSecureSms(false);
      clearTimeout(timeId);
      setAuthTokenToLs(data?.result?.access_token);
      fetchGSMBalances({ fetchLoan: loanConfig?.isEnabled });
      navigate(HOME_PAGE, { replace: true });
    }, 3000);
  }

  function callSendOtp() {
    sendOtp(getPhoneNumberFromLs(), setApiErrorMsg, setDoShowOTPScreen, setOTPId);
  }

  function getFailedMessage(key: string, param: string = "") {
    let msisdn = ""
    if (param)
      msisdn = `${countryCode} ${addSpaceInMsisdn(param)}`;
    let msg = getText(key, msisdn);
    setFailedMessageKey(msg);
  }

  function closeSecureSMS() {
    closeSecureSms(false);
  }

  function failedFunction() {
    if (failedCase === CALL_SEND_OTP)
      callSendOtp();
    else
      closeSecureSMS();
  }

  function handleDefaultState() {
    if (timeouted.current) {
      sendGA4Event({ category: "secure-sms", action: "login_securesms_smscheck_fail_timedout" });
      return TIMEOUT_STATE;
    } else {
      return currState;
    }
  }

  function renderStates() {
    switch (currState) {
      case INITIAL_STATE:
        return <SecureSmsInitiatedModal isModalOpen={true} secureToken={secureToken} shortHand={shortHand} startPolling={startVerificationCall} closeSecureSms={closeSecureSMS} />
      case POLLING_STATE:
        return <SecureSmsPollingModal isModalOpen={true} setCurrState={setCurrState} />
      case SUCCESS_STATE:
        return <SecureSmsSuccessModal isModalOpen={true} />
      case TIMEOUT_STATE:
        return <SecureSmsTimeoutModal closeSecureSms={closeSecureSMS} resendSecureSmsToken={resendSecureSmsToken} />
      case LOCKED_STATE:
        return <SecureSmsLockedModal closeSecureSms={closeSecureSMS} />
      case FAILED_STATE:
        return <SecureSmsFailedModal parentFunc={failedFunction} isModalOpen={true} closeSecureSms={closeSecureSMS} messageText={failedMessageKey} ctaKey={failedCtaKey} />
    }
  }

  return (
    <div className="secure-sms-main-ctn">
      {renderStates()}
    </div>
  )
}

export default SecureSms;