import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import api from "../../../services/apiService";
import {
  callGetAMUserProfile,
  callGetAMUserProfileFailed,
  callGetAMUserProfileSuccess,
  callGetUserInfo,
  callGetUserInfoFailed,
  callGetUserInfoSuccess,
  callGetIncentive,
  callSetUserPreferences,
} from "../../slices/homeSlice";
import { AM_DOWN_CODE } from "../../../data/constants";
import { getText } from "../../../helpers/translateHelper";

function* getUserInfo(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.getUserInfo(),
      data: payload.data,
      hideLoader: payload?.hideLoader,
    });
    if (!!response?.data && response?.data?.statusCode === 200) {
      yield put(callGetUserInfoSuccess(response.data));
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else {
      ToastNotifyError(response?.data?.message);
      yield put(callGetUserInfoFailed(response));
    }
  } catch (error) {
    console.log("Something went wrong", error);
    yield put(callGetUserInfoFailed(error));
  }
}

function* getAMUserProfile(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.getAMProfile(),
      data: payload.data,
      hideLoader: payload?.hideLoader,
    });
    if (response?.data?.result && response?.data?.statusCode === 200 && response?.status === 200) {
      response.data.result["userBarred"] = !!response.data.result?.userBarred;
      response.data.result["userId"] = !!response.data.result?.userId;
      yield put(callGetAMUserProfileSuccess(response.data));
      !!action?.payload?.callback && action.payload.callback(response.data);
    } else if (response?.data?.statusCode === AM_DOWN_CODE && response?.status === 200) {
      yield put(callGetAMUserProfileFailed(response));
    } else {
      !!action?.payload?.errorCallback && action.payload.errorCallback(response.data);
      yield put(callGetAMUserProfileFailed(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(callGetAMUserProfileFailed(error));
  }
}

function* getIncentive(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.captureIncentive(),
      data: payload.data,
      hideLoader: payload?.hideLoader
    });
    if (response?.data?.statusCode === 200) {
      payload?.callback(response.data?.result);
    } else {
      payload?.errorCallback();
    }
  } catch (error) { }
}

function* setUserPreferences(action: PayloadAction<any>): Generator {
  let payload = action.payload;
  try {
    const response: any = yield api.request({
      method: "POST",
      url: ENDPOINTS.HOME.setUserConfig(),
      data: payload.data,
      hideLoader: payload?.hideLoader
    });
    if (response?.data?.statusCode === 200) {
      payload?.successCallback();
    } else {
      payload?.errorCallback();
    }
  } catch (error) { }
}

export default function* root(): Generator {
  yield takeLatest(callGetUserInfo.type, getUserInfo);
  yield takeLatest(callGetAMUserProfile.type, getAMUserProfile);
  yield takeLatest(callGetIncentive.type, getIncentive);
  yield takeLatest(callSetUserPreferences.type, setUserPreferences);
}
