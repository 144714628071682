import { IGetLobPayload, IGetLobPayloadV2 } from "../../../config/commonInterfaces";
import { parseMsisdn } from "../../../utils/commonUtils";

/**
 * Create request payload for line of business api
 * Accepts request data, success & error flow callback
 * @deprecated
 */
export const createGetLOBPayload = (phoneNumer: string, callback: IGetLobPayload["callback"], errorCallback: IGetLobPayload["errorCallback"]): IGetLobPayload => {
  return {
    data: {
      siNumber: parseMsisdn(phoneNumer),
      flowType: ""
    },
    callback,
    errorCallback
  };
}

export const createGetLOBPayloadV2 = (phoneNumer: string, callbackV2: IGetLobPayloadV2["callbackV2"], errorCallback: IGetLobPayloadV2["errorCallback"]): IGetLobPayloadV2 => {
    return {
      data: {
        siNumber: parseMsisdn(phoneNumer),
        flowType: ""
      },
      callbackV2,
      errorCallback
    };
}

export const createSendOtpPayload = (phoneNumber: string, callback: any) => {
  return {
    data: {
      username:phoneNumber,
     "communicationType":"SMS"
  
    },
    callback
  };
}

export const createResendOtpPayload = (otpId: string,phoneNumber: string, callback: any) => {
  return {
    data: {
      "username":phoneNumber,
      "communicationType":"SMS"
    },
    callback
  }
}

export const createVerifyOtpPayload = (otp: string, otpId: string, phoneNumber: string, callback: any) => {
  return {
    data: {
      otp: otp,
      otpId: otpId,
      username: phoneNumber
    },
    callback
  }
};