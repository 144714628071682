import { getDeviceInfo } from "../../../helpers/commonHelpers";
import { createSendOtpPayload } from "../";
import { getPhoneNumberFromLs, parseMsisdn } from "../../../utils/commonUtils";
import { callGetSecureSmsToken, callSendOtp } from "../../../Redux/slices/loginSlice";
import { store } from "../../../Redux";
import { getText } from "../../../helpers/translateHelper";
import { sendGA4Event } from "../../../services/ga4";
import { callCheckGSMBalance } from "../../../Redux/slices/balanceSlice";
import { createCheckGSMBalancePayload } from "../../balance/config/balancePayloads";
import { SOME_ERR } from "../../../data/constants";

export const isLoginMsisdnsLenInLimit = (loginMsisdn: string, msisdnLength: number) => {
  const zeroIsPrefixed = loginMsisdn.startsWith("0");
  return (zeroIsPrefixed && loginMsisdn.length <= +msisdnLength + 1) || (!zeroIsPrefixed && loginMsisdn.length <= +msisdnLength);
}

export const isLoginMsisdnsLenComplete = (loginMsisdn: string, msisdnLength: number) => {
  const zeroIsPrefixed = loginMsisdn.startsWith("0");
  return (zeroIsPrefixed && loginMsisdn.length === +msisdnLength + 1) || (!zeroIsPrefixed && loginMsisdn.length === +msisdnLength);

}

export const isSecureSmsSupported = (secureSmsNonSupportedBrowsers: any) => {
  let flag = false;
  const device = getDeviceInfo();
  const ind = secureSmsNonSupportedBrowsers && secureSmsNonSupportedBrowsers.findIndex((item: any) => ((item?.browserName.toLowerCase() === device?.BrowserName.toLowerCase()) && (item?.osName.toLowerCase() === device?.OSName.toLowerCase())));
  if (ind === -1) flag = true;
  return flag;
}

export function sendOtp(rawMsisdn: any, setApiErrorMsg: any, setDoShowOTPScreen: any, setOTPId: any) {
  store.dispatch(callSendOtp(createSendOtpPayload(parseMsisdn(rawMsisdn), (response: any) => {
    if (response.statusCode === 200) {
      setApiErrorMsg("");
      setDoShowOTPScreen(true);
      setOTPId(response?.result?.otpId || "");
      sendGA4Event({ category: "login", action: "login_otp_otpsent" });
    }
    else if (response?.message) {
      setApiErrorMsg(response.message || getText("SOME_ERR"));
      sendGA4Event({ category: "login", action: "otp_sent_failed" });
    }
  })));
}

export function getSecureSMSToken(rawMsisdn: string, setSecureToken: any, setShorthand: any, setShowSecureSms: any, setSecureSmsErrCode: any, setSecureSmsError?: (arg: string) => void) {
  const deviceInfo = getDeviceInfo();
  let payload = {
    data: {
      "msisdn": rawMsisdn,
      "osystem": deviceInfo.OSName,
      "browser": deviceInfo.BrowserName
    },
    callback: function (response: any) {
      if (response.statusCode === 200 && !!response?.result?.encryptedToken && !!response?.result?.shortCode) {
        setSecureToken(response.result.encryptedToken);
        setShorthand(response.result.shortCode);
        setShowSecureSms(true);
        setSecureSmsError?.("");
      } else {
        setSecureSmsErrCode(response?.statusCode);
        setShowSecureSms(false);
        setSecureSmsError?.(response.message || getText(SOME_ERR));
      }
    }
  };
  store.dispatch(callGetSecureSmsToken(payload));
}

export function fetchGSMBalances(payload: any, hideLoader?: boolean) {
  store.dispatch(callCheckGSMBalance(createCheckGSMBalancePayload({ msisdn: getPhoneNumberFromLs(), ...payload }, hideLoader)));
}