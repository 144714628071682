import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";

import { ToastNotifyError } from "../../../components/ToastNotify";
import { ENDPOINTS } from "../../../config/endpoints";
import { SOME_ERR, fallbackTranslations } from "../../../data/constants";
import api from "../../../services/apiService";
import { callGetStaticPage, callGetStaticPageSuccess, callLogout } from "../../slices/appSettingsSlice";
import { callFetchTranslations } from "../../slices/translationSlice";

function* getStaticPages(action: PayloadAction<any>): Generator {
    let payload = action.payload;
    try {
        const response: any = yield api.request({
            method: "GET",
            url: ENDPOINTS.CMS,
            params: payload.paramObj
        });
        if (response?.data?.result && response?.data?.statusCode === 200) {
            yield put(callGetStaticPageSuccess({ code: payload.appSettingCode, data: response.data.result?.packageHtml }));
            payload?.callback();
        } else {
            ToastNotifyError(fallbackTranslations[SOME_ERR])
        }
    } catch (error) {
        ToastNotifyError(fallbackTranslations[SOME_ERR])
    }
}

function* logoutSaga(action: PayloadAction<any>): Generator {
    let payload = action.payload;
    put(callFetchTranslations({}));
    try {
        const response: any = yield api.request({
            method: "GET",
            url: ENDPOINTS.LOGOUT,
            hideLoader: payload?.hideLoader
        });
        if (response?.data?.statusCode === 200) {
            yield payload?.callback();

        } else {
            ToastNotifyError(fallbackTranslations[SOME_ERR])
        }
    } catch (error) {
        ToastNotifyError(fallbackTranslations[SOME_ERR])
    }
}

export default function* root(): Generator {
    yield takeLatest(callGetStaticPage.type, getStaticPages);
    yield takeLatest(callLogout.type, logoutSaga);
}
