import backIcn from "../../assets/icons/ic_back.svg";
import { getText } from "../../helpers/translateHelper";


interface Props {
  parentFunc: (...args: any[]) => void;
}

const BackButton = ({ parentFunc }: Props) => {


  return (
    <div className="back-btn-ctn bg-white text-[#2C3E50] text-16 py-6 px-12 inline-flex  min-w-[66px] rounded-[46px]  items-center border border-[#e6e6e6]" onClick={parentFunc}>
      <div className="icn">
        <img src={backIcn} alt="back-icon" className="min-w-[16px]" />
      </div>
      <div className="text ml-4">
        {getText("BACK")}
      </div>
    </div>
  )
}

export default BackButton;