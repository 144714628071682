import { useMemo } from "react";
import { webLandingPageIcns } from "../../config/icons";
import SliderComponent from "./Components/SliderComponent";
import UserInputComponent from "./Components/UserInputComponent";
import IntroductionComponent from "./Components/IntroductionComponent";
import { isTabletDevice } from "../../utils/commonUtils";

const WebLandingPage = () => {

  const isTablet = useMemo(() => {
    return isTabletDevice();
  }, [])

  return (
    <div className="flex flex-row gap-50 justify-center align-middle self-center h-[100vh]">
      {!isTablet ? <div className="flex  justify-center align-middle self-center w-[50%] h-[100%]">
        <div className="flex bg-cover justify-center align-middle self-center">
          <img alt='web-phone-icn' src={webLandingPageIcns.webPageMobileIcn} width={'75%'} height={'auto'} />
        </div>
      </div> : null}
      <div className={`flex bg-cover bg-[url('assets/icons/web_page_bg_icn.svg')] justify-center w-[${!isTablet ? '50%' : '100%'}]`}>
        <div className={`flex flex-col gap-30 relative min-w-[680px] pr-18 self-center max-w-[680px] ${!isTablet ? 'left-[-15%]' : ''}`}>
          <IntroductionComponent isTablet={isTablet} />
          <UserInputComponent />
          <SliderComponent />
        </div>
      </div>
    </div>
  )
}

export default WebLandingPage