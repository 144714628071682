import React from 'react'
import { getText } from '../../helpers/translateHelper';
import { browserIcns } from '../../config/icons';

interface Props {
  isDashboardScreen: boolean
}

const BrowserSuported = ({ isDashboardScreen }: Props) => {
  return (
    <>
      <div className="browser-list-description text-center w-[70%] mx-auto text-[#1F2436] text-12">
        <div dangerouslySetInnerHTML={{ __html: getText(isDashboardScreen ? "BROWSER_LIST_DASHBOARD_DESCRIPTION" : "BROWSER_LIST_DESCRIPTION") }} />
      </div>
      <div className="logos-ctn flex justify-center pb-32 mt-20">
        {Object.values(browserIcns).map((logo, i) => {
          return (
            <React.Fragment key={i}>
              <img className="w-42 h-42 mr-4" src={logo} alt="icn" />
            </React.Fragment>
          );
        })}
      </div>
    </>
  )
}

export default BrowserSuported