import { useEffect, useState } from 'react'
import { GLOBAL } from '../data/globals';

const useDetectFieldIsInFocus = () => {
  const [isFieldInFocus, setIsFieldInFocus] = useState<boolean>(false);

  useEffect(() => {
    const handleOpen = (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.tagName === GLOBAL.INPUT) {
        setIsFieldInFocus(true);
      }
    }
    document.addEventListener(GLOBAL.FOCUS_IN, handleOpen);

    const handleClose = () => {
      setIsFieldInFocus(false);
    }
    document.addEventListener(GLOBAL.FOCUS_OUT, handleClose);

    return () => {
      document.removeEventListener(GLOBAL.FOCUS_IN, handleOpen);
      document.removeEventListener(GLOBAL.FOCUS_OUT, handleClose);
    }
  }, []);

  return isFieldInFocus;
}

export default useDetectFieldIsInFocus;