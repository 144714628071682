import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callLaunchConfigApi } from "../../Redux/slices/configSlice";
import appStoreIcn from "../../assets/icons/ic_appstore.svg";
import playStoreIcn from "../../assets/icons/ic_playstore.svg";
import { APP_STORE_URL, PLAY_STORE_URL } from "../../config/endpoints";
import { getText } from "../../helpers/translateHelper";
import { RootState } from "../../Redux";
import { V1, BLOCK_HEADING1, BLOCK_HEADING2 } from "../../data/constants";
import icons from "../../config/icons";
import WebLandingPage from "../WebLandingPage/WebLandingPage";

const BlockWebAccess = () => {
  const version = useSelector((state: RootState) => state.config?.launchConfig?.version);
  const viewLandingPage = useSelector((state: RootState) => state?.config?.launchConfig?.viewLandingPage);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(callLaunchConfigApi({}))
  }, [])

  return (
    <div className={`block fixed top-0 bottom-0 right-0 left-0 max-h-[100vh] z-50 flex-wrap justify-between overflow-y-scroll text-center bg-white`}>
      {viewLandingPage ? <WebLandingPage /> : <>
        <div className="main-body flex flex-col items-center pt-75">
          <div className="icon">
            <img src={version === V1 ? icons.blockIcon : icons.ic_ban_red} alt="block-icon" />
          </div>
          <div className={`text text-20  font-bold mt-44 ${version === V1 ? 'text-white' : 'text-black'}`}>
            {getText(BLOCK_HEADING1)}
          </div>
        </div>
        <div className="footer-section flex flex-col items-center mb-[60px] ">
          <div className={`text-20  font-bold ${version === V1 ? 'text-white' : 'text-black'}`}>
            {getText(BLOCK_HEADING2)}
          </div>
          <div className="icons-section flex">
            <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
              <img src={appStoreIcn} alt="app-store-icn" className="w-[100%] max-w-[183px]" />
            </a>
            <a href={PLAY_STORE_URL} target="_blank" rel="noreferrer">
              <img src={playStoreIcn} alt="play-store-icn" className="w-[92%] max-w-[183px] ml-24" />
            </a>
          </div>
        </div></>
      }
    </div>
  )
}

export default BlockWebAccess;