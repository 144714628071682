import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux";
import { getText } from "../../../../helpers/translateHelper";
import { PrefixedMsisdn } from "../../../../config/commonInterfaces";
import { isLoginMsisdnsLenComplete } from "../../helpers/loginHelper";
import { headings } from "../../../../data/constants";
import BrowserSuported from "../../../../components/BrowserSupported";
import useDetectFieldIsInFocus from "../../../../hooks/useDetectFieldIsInFocus";

interface ILoginProps {
  handleSubmitProp: (...args: any[]) => void;
  prefixedMsisdn: PrefixedMsisdn;
  updateValue: (event: ChangeEvent<HTMLInputElement>) => void;
  parentErrorMsg: string;
  setParentErrorMsg: (err: string) => void;
}

//Handles airtel prepaid number validation for login
//also render parents error messages
export function PhoneNumber({ handleSubmitProp, prefixedMsisdn, updateValue, parentErrorMsg, setParentErrorMsg }: ILoginProps) {

  const countryCode = useSelector((state: RootState) => state.config?.launchConfig?.phoneCode);
  const msisdnLength = useSelector((state: RootState) => state.config?.launchConfig?.msisdnLength);
  const enableSecureSms = useSelector((state: RootState) => state.config?.launchConfig?.enableSecureSms);
  const [canShowCountryCode, setCanShowCountryCode] = useState<boolean>(false);
    const isKeyboardOpen = useDetectFieldIsInFocus();

  const handleBlur = () => {
    if (!prefixedMsisdn.value) {
      setCanShowCountryCode(false);
    }
  }

  const handleOnFocus = () => {
    setCanShowCountryCode(true);
  };

  return (
    <div className="phone-number-ctn w-full mt-32">
      <div className="phone-number-input relative">
        {(canShowCountryCode || !!prefixedMsisdn.value) &&
          <span className={`absolute text-20 top-10 left-16 font-medium  pointer-events-none${(parentErrorMsg) ?
            "text-red-500 border-red-500" : " border-E8E8E8 text-secondaryBlack"}`}>
            {countryCode}
          </span>
        }
        <input className={`outline-0 mobile-input-box text-20 h-[52px] border bg-transparent  w-full px-20 py-10  focus:placeholder-transparent 
          ${(parentErrorMsg) ? "text-red-500 border-red-500 placeholder-transparent" :
            " border-[#E8E8E8] placeholder-[#1f24364d] text-secondaryBlack"} ${canShowCountryCode ? "pl-75" : "pl-20"}`}
          type="text" minLength={+msisdnLength}
          placeholder={getText("MOBILE_NUMBER")}
          inputMode="numeric" autoComplete="one-time-code" value={prefixedMsisdn.value}
          onChange={updateValue} onFocus={handleOnFocus} onBlur={handleBlur}
        />
      </div>
      {(parentErrorMsg) &&
        <div className="error-msg text-red-500 text-12 mt-8 font-[500]">
          {parentErrorMsg}
        </div>
      }
      <button className={`send-otp-btn mt-16 w-full bg-redSubmitButton text-white font-medium text-center text-14 py-10 rounded-md
          ${!isLoginMsisdnsLenComplete(prefixedMsisdn.value, +msisdnLength) || (parentErrorMsg) ? "opacity-50 pointer-events-none" : ""}`}
        onClick={handleSubmitProp}>
        {enableSecureSms ? getText(headings.PROCEED_ALL_CAPS) : getText("SEND_OTP")}
      </button>
      {!isKeyboardOpen ?
        <div className="browser-list fixed bottom-0 left-0 right-0"> <BrowserSuported isDashboardScreen={false} /></div>
        :
        null
      }
    </div>
  );
}
