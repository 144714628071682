import { useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import icons from "../../../config/icons";
import { getText } from "../../../helpers/translateHelper";
import { PROCEED_TO_SEND_SMS, REQUEST_TIMEOUT_HEADING, TIMEOUT_TEXT } from "../constants";
import { getOS } from "../../../utils/browserDetails";
import Bowser from "bowser";
import { sendGA4Event } from "../../../services/ga4";

interface Props {
  closeSecureSms: () => void;
  resendSecureSmsToken: () => void;
}

const SecureSmsTimeoutModal = ({ closeSecureSms, resendSecureSmsToken }: Props) => {

  useEffect(() => {
    const os = getOS();
    const browser = Bowser.getParser(window?.navigator?.userAgent)?.getBrowser();
    sendGA4Event({ category: "secure_sms_failed", action: "secure_sms_failed_device_details", data: { "browser_name": browser?.name || "", "browser_version": browser.version || "", "os_details": os?.name || "", "os_version": os.version || "", "os_versionName": os.versionName || "", "screen_size": `${window?.screen?.width}*${window?.screen?.height}` } });
  }, [])

  return (
    <Modal
      isOpen={true}
      onClose={() => { }}
    >
      <div className="acoount-locked-modal-main  bg-white w-[100vw] rounded-t-[20px] p-26 flex flex-col justify-center text-[#232530]">
        <div className="main-heading flex justify-between">
          <div className="heading-main text-18 font-bold">
            {getText(REQUEST_TIMEOUT_HEADING)}
          </div>
          <div className="close-icon" onClick={closeSecureSms}>
            <img src={icons.redCloseIcon} alt="red-close-icn" />
          </div>
        </div>
        <div className="icon-ctn mt-32 flex justify-center">
          <img src={icons.timeoutIcon} alt="icn" />
        </div>
        <div className="description-ctn mt-26 text-center">
          {getText(TIMEOUT_TEXT)}
        </div>
        <div className="ok-cta rounded-[4px] bg-[#293F55] text-white py-12 text-center font-bold text-18 mt-24" onClick={resendSecureSmsToken}>
          {getText(PROCEED_TO_SEND_SMS)}
        </div>
      </div>
    </Modal>
  )
}

export default SecureSmsTimeoutModal;