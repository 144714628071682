import { useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "../../../components/Modal/Modal";
import Timer from "../../../components/Timer";
import icons from "../../../config/icons";
import { sendGA4Event } from "../../../services/ga4";
import { getText } from "../../../helpers/translateHelper";
import { TIMEOUT_STATE, VERFYING_TEXT, VERIFYING_MOBILE_NUMBER } from "../constants";
import { RootState } from "../../../Redux";
import CardLoader from "../../../components/CardLoader";

interface Props {
  isModalOpen: boolean;
  setCurrState: (val: string) => void;
}
const SecureSmsPollingModal = ({ isModalOpen, setCurrState }: Props) => {
  const secureSmsTimeout = useSelector((state: RootState) => state.config.launchConfig.secureSmsTimeout);

  return (
    <>
      {isModalOpen ? (
        <Modal isOpen={true} onClose={() => { }}>
          <div className="text-center fixed bottom-[0]  left-0   w-[100vw] min-h-[200px] rounded-[20px] rounded-br-none rounded-bl-none bg-white flex flex-col items-center py-[40px] px-[25px]">
            <h4 className="text-18 font-bold mb-[30px]">
              {getText(VERIFYING_MOBILE_NUMBER)}
            </h4>
            <img
              className="mb-[15px] inline-block mr-[-20px] "
              src={icons.mobileVerify}
              alt={icons.mobileVerify}
            />
            <CardLoader containerStyle="justify-center mt-[15px]" />
            <div className="flex items-center mb-[50px] mt-[10px]">
              <img src={icons.clock_time} alt={icons.clock_time} />
              <span className="ml-4 text-14 text-[#191A1C]">  <Timer className="text-[#EC1B25] text-14  font-[800]" defaultTimer={secureSmsTimeout}
                onTimeout={
                  () => {
                    setCurrState(TIMEOUT_STATE)
                  }
                } /> </span>
            </div>
            <p className="text-16 text-[#4A4343]" dangerouslySetInnerHTML={{ __html: getText(VERFYING_TEXT) }} />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default SecureSmsPollingModal;
