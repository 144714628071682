export const recharge = {
  RECHARGE: "/airtime" as const,
  SELF: "/airtime/self",
  SELF_PIN: "/airtime/self/pin",
  OTHERS: "/airtime/others",
  OTHERS_MOB: "/airtime/others/mob",
  OTHERS_PAYMENT_METHODS: "/airtime/others/methods",
  OTHERS_PAYMENT_AM_PIN: "/airtime/others/methods/am/pin",
};

export const balanceRoute = {
  BALANCE: "/balance",
  DATA: "/balance/data",
  VOICE_MENU: "/balance/voice-menu",
  VOICE_OFFNET: "/balance/voice-offnet",
  VOICE_ONNET: "/balance/voice-onnet",
  SMS: "/balance/sms",
  AIR_TIME: "/balance/air-time",
  AIRTEL_MONEY: "/balance/airtel-money",
  DISPLAY_AM_BALANCE: "/balance/am/display",
  ACTIVE_PACKS: "/balance/activepacks"
};

export const buyBundle = {
  SELF: "/bundle/self",
  OTHERS: "/bundle/others",
  BUNDLE_MENU: "/bundle" as const,
  OTHERS_MOB: "/bundle/others/mob",
  PAYMENT_METHODS: "/bundle/categories/bundle/methods",
  AM_PIN: "/bundle/categories/bundle/methods/am/pin",
  BUNDLE_CATEGORIES: "/bundle/categories",
  SUB_BUNDLES: "/bundle/categories/bundles",
  CONFIRMATION: "/bundle/categories/bundle/confirmation",
};

export const AM_PIN_ERROR_RT = "/am/pin/error";

export const SEND_MONEY = "/money";
export const PAY_BILL = "/bill";

export const OTP = "/otp";
export const HOME_PAGE = "/home";

export const REQUEST_SUBMITTED = "/submitted";
export const SUCCESS = "/submitted";

export const USSD_ROUTE = "/ussd";
export const FAV_ROUTE = "/favourite";
export const MY_FAV = "/my-favourite";

export const APP_SETTINGS_ROUTE = "/home/app-settings";
export const STATIC_APP_SETTINGS_ROUTE = "/home/app-settings/static";
export const HELP_AND_SUPPORT_ROUTE = "/home/app-settings/help-and-support";
export const REQUEST_TIMEDOUT_ROUTE = "/request-timeout";
export const IN_PROGRESS_ROUTE = "/in-progress"
export const LANGUAGE_ROUTE = "/home/app-settings/language"


export const airtelMoney = {
  TRANSACTION_HISTORY: "/am/transaction-history",
  SET_QUESTION: "/am/set-question",
  FORGOT_PIN: "/am/forgot-pin"
};
export const PAYMENT_METHODS_ROUTE = "/payment-options";

export const SUCCESS_V2 = "/success-v2";
export const WITHDRAW_CASH = "/am/withdraw-cash"
export const BUY_GOODS_RT = "/buy-goods"
export const LOG_OUT = "/logout"

export const FAILED_V2 = "/failed-v2";
export const IN_COMPATIBLE = "/in-compatible";
export const HE_Error = "/he-error";
export const LOGIN_ROUTE = "/login";
export const TRANSACTION_DETAILS_ROUTE = "/transaction-details"
export const CHANGE_PIN_ROUTE = "/change-pin"
export const FAQ_ROUTE = "/faq"
export const AIRTIME_LOAN = "/airtime-loan"
export const REPAY_AIRTIME_LOAN = "/repay-loan"
export const SELECT_LOAN = "/select-loan"
export const LOAN_TRANSACTION_HISRTORY = "/loan-transaction-history"
export const DEFAULT_ROUTE = "/"
