import { useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import icons from "../../../config/icons";
import { getText } from "../../../helpers/translateHelper";
import { MOBILE_NUMBER_VERIFIED, MOBILE_VERIFIED_TEXT } from "../constants";
import { sendGA4Event } from "../../../services/ga4";

interface Props {
  isModalOpen: boolean;
}
const SecureSmsSuccessModal = ({ isModalOpen }: Props) => {
  useEffect(()=>{
    sendGA4Event({category: "secure-sms", action: "login_securesms_smscheck_success"});
  }, []);

  return (
    <>
      {isModalOpen ? (
        <Modal isOpen={true} onClose={() => { }}>
          <div className="text-center fixed bottom-[0]  left-0   w-[100vw] min-h-[200px] rounded-[20px] rounded-br-none rounded-bl-none bg-white flex flex-col items-center py-[40px] px-[25px]">
            <h4 className="text-18 font-bold ">
              {getText(MOBILE_NUMBER_VERIFIED)}
            </h4>
            <img
              className="inline-block my-[50px]"
              src={icons.mobileSuccessTick}
              alt={icons.mobileSuccessTick}
            />

            <p className="text-16 text-[#4A4343]" dangerouslySetInnerHTML={{ __html: getText(MOBILE_VERIFIED_TEXT) }} />
          </div>
        </Modal >
      ) : null}
    </>
  );
}

export default SecureSmsSuccessModal;