import Modal from "../../../components/Modal/Modal";
import icons from "../../../config/icons";
import { getText } from "../../../helpers/translateHelper";
import { getPhoneCodeAndMsisdn, getPhoneNumberFromLs } from "../../../utils/commonUtils";
import { INITIATE_PAGE_POINT_1, INITIATE_PAGE_POINT_2, INITIATE_PAGE_POINT_3, INITIATE_PAGE_POINT_4, MOBILE_VERIFICATION_HEADING, PROCEED_CTA_TEXT } from "../constants";

interface Porps {
  isModalOpen: boolean;
  secureToken: string;
  shortHand: string;
  startPolling: () => void;
  closeSecureSms: () => void;
}

const SecureSmsInitiatedModal = ({ isModalOpen, secureToken, shortHand, startPolling, closeSecureSms }: Porps) => {
  return (
    <>
      {isModalOpen ?
        <Modal
          isOpen={true}
          onClose={() => { }}
        >
          <div className="secure-sms-main-modal-ctn bg-white w-[100vw] rounded-t-[20px] p-26">
            <div className="icn-heading-ctn flex justify-between items-start">
              <div className="main-heading text-[#232530] text-18 font-bold w-80%">
                {getText(MOBILE_VERIFICATION_HEADING)}
              </div>
              <div className="close-icon" onClick={closeSecureSms}>
                <img src={icons.redCloseIcon} alt="red-close-icn" />
              </div>
            </div>
            <div className="icn-ctn my-28 flex justify-center">
              <img src={icons.mobileVerify} alt="icn" />
            </div>
            <div className="points-ctn text-[#4A4343] text-14">
              <div className="points-ctn text-[#4A4343] text-14">
                <div className="point flex">
                  <div className="icn mr-10">
                    <img className="min-w-[30px]" src={icons.checkIcon} alt="icn" />
                  </div>
                  <div className="text-ctn" dangerouslySetInnerHTML={{ __html: getText(INITIATE_PAGE_POINT_1, getPhoneCodeAndMsisdn(getPhoneNumberFromLs())) }}>
                  </div>
                </div>
                <div className="point flex mt-20">
                  <div className="icn mr-10">
                    <img className="min-w-[30px]" src={icons.checkIcon} alt="icn" />
                  </div>
                  <div className="text-ctn">
                    {getText(INITIATE_PAGE_POINT_2)}
                  </div>
                </div>
                <div className="point flex mt-20">
                  <div className="icn mr-10">
                    <img className="min-w-[30px]" src={icons.checkIcon} alt="icn" />
                  </div>
                  <div className="text-ctn">
                    {getText(INITIATE_PAGE_POINT_3)}
                  </div>
                </div>
                <div className="point flex mt-20 mb-22">
                  <div className="icn mr-10">
                    <img className="min-w-[30px]" src={icons.checkIcon} alt="icn" />
                  </div>
                  <div className="text-ctn">
                    {getText(INITIATE_PAGE_POINT_4)}
                  </div>
                </div>
              </div>
              <a className="secure-sms-proceed-cta block rounded-[4px] bg-[#293F55] text-white py-12 text-center font-bold text-18" href={`sms:${shortHand}?body=${secureToken}`} onClick={startPolling}>
                <span className="secure-sms-text">
                  {getText(PROCEED_CTA_TEXT)}
                </span>
              </a>
            </div>
          </div>

        </Modal>
        :
        null
      }
    </>
  )
}

export default SecureSmsInitiatedModal;