export enum EventAction {
  SPLASH_SCREEN_PAGE_VIEW = 'splash_screen_page_view'
}

export const AIRTIME_LOAN_EVENTS = {
  AIRTIME_LOAN: "airtime-loan",
  FULL_PAYMENT: "fullpymt",
  PARTIAL_PAYMENT: "partpymt",
  TAB_CLICKED: "airtime_loan_tab_clicked",
  TAB_SCROLLED: "airtime_loan_tab_scrolled",
  REPAY_LOAN_SELECTED: "airtime_loan_re-pay_loan_selected",
  AMOUNT_ENTERED_FULL_PAYMENT: "airtime_loan_amount_entered_full_payment",
  PAYMENT_MODE_SELECT_AM: "paymentmodeselect_AM",
  PAYMENT_AM_PIN_ENTERED: "am_pin_entered",
  PAYMENT_SUCCESSFUL_AM: "paymentsuccessful_AM",
  PAYMENT_FAILED: "paymentfailed",
  PAYMENT_FAILED_AM: "paymentfailed_am",
  PAYMENT_FAIL_AM_INCORRECTPIN: "pymtfail_am_incorrectpin",
  PAYMENT_FAIL_AM_INSUFFICIENTBAL: "pymtfail_am_insufficientbal",
  PAYMENT_FAIL_AM_OTHERS: "pymtfail_am_others",
  AMOUNT_ENTERED_PARTIAL_PAYMENT: "airtime_loan_amount_entered_partial_payment",
  AIRTIME_LOAN_OFFER: "airtime_loan_offer",
  OFFER_SELECTED: "selected",
  OFFER_AMOUNT_SELECTED: "amount_selected",
  OFFER_AMOUNT_CONFIRMS_DETAILS: "amount_confirms_details",
  OFFER_LOAN_SUCCESSFUL: "loan_successful",
  TXHISTORY_CLICKED: "airtime_loan_txhistory_clicked",
  TRXHISTORY_LANDINGPAGE_VIEWED: "airtime_loan_trxhistory_landingpage_viewed",
  TRXHISTORY_LANDINGPAGE_ALL: "airtime_loan_trxhistory_landingpage_all",
  TRXHISTORY_LANDINGPAGE_SELECTED: "airtime_loan_trxhistory_landingpage",
}

export const LANDING_PAGE_EVENTS = {
  USER_ENTERS_MSISDN: "user_msisdn_entered_to_get_applite_link",
  APP_LITE_LINK_SENDS_SUCCESS: "app_lite_link_sends_success",
  APP_LITE_LINK_SENDS_FAILED: "app_lite_link_sends_failed",
  USER_REDIRECT_FROM: "user_redirects_from_"
}