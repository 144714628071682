interface ICardLoader {
  containerStyle?: string;
  dotCount?: number;
  height?: number;
  width?: number;
}
// delay defined as per index value
const delayPerDot: { [key: number]: number } = {
  0: 0.3,
  1: 0.15,
  2: 0.25,
};
const CardLoader = ({ containerStyle = '', dotCount = 3, height = 10, width = 10 }: ICardLoader) => {
  const dotArr = Array.from({ length: dotCount }, (_, i) => i);
  const dimensionCss = {
    height: `${height}px`,
    width: `${width}px`,
  }
  return (
    <div className={`card-loader-main flex ${containerStyle}`}>
      <div className="flex justify-center items-center">
        {dotArr.map((_, index) => (
          <div
            key={index}
            className={`mx-4 bg-[#d1d1d1] rounded-full animate-bounce`}
            style={{ animationDelay: `${delayPerDot[index] || 0}s`, ...dimensionCss }}
          ></div>
        ))}
        <div className={`mx-4 bg-[#d1d1d1]  rounded-full animate-bounce`} style={{ ...dimensionCss }}></div>
      </div>
    </div>
  );
};

export default CardLoader;