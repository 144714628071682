export const LANDING_PAGE_TEXT = {
  SCAN_QR: "SCAN_QR",
  SCAN_QR_DESCRIPTION: "SCAN_QR_DESCRIPTION",
  LANDING_PAGE_ENTER_NUMBER: "LANDING_PAGE_ENTER_NUMBER",
  ENTER_AIRTEL_NUMBER_TEXT: "ENTER_AIRTEL_NUMBER_TEXT",
  WELCOME_TO: "WELCOME_TO",
  AIRTEL_LITE: "AIRTEL_LITE",
  GET_LINK_DESCRIPTION: "GET_LINK_DESCRIPTION",
  GET_LINK: "GET_LINK",
  LANDING_PAGE_INPUT_PLACEHOLDER: 'LANDING_PAGE_INPUT_PLACEHOLDER',
}

export const LS_TEXT = 'ls'
export const LANDING_PAGE_SLIDER_ARRAY = [1, 2, 3, 4, 5];
