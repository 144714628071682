import { useEffect, useState } from "react";
import { RootState } from "../../../Redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { callLaunchConfigApi } from "../../../Redux/slices/configSlice";
import BackButton from "../../../components/BackButton";
import { Login } from "../../../features/login";
import { HOME_PAGE, IN_COMPATIBLE } from "../../../routes/routeConstants";
import { sendGA4Event } from "../../../services/ga4";
import {
  checkMinimumScreenSize,
  checkValidBrowser,
  getAuthTokenFromLs,
  getPhoneNumberFromLs,
} from "../../../utils/commonUtils";
import LoginOTP from "../components/LoginOTP";
import { callCheckGSMBalance } from "../../../Redux/slices/balanceSlice";
import { createCheckGSMBalancePayload } from "../../balance/config/balancePayloads";
import SecureSms from "../../SecureSms";
import { getSecureSMSToken } from "../helpers/loginHelper";
import { updateAddtoHomePopToggle } from "../../../helpers/commonHelpers";

//decides which screen to be rendered in the login flow
const LoginFlow = () => {
  const loanConfig = useSelector((state: RootState) => state.config?.launchConfig?.loanConfig);
  const [doShowOTPScreen, setDoShowOTPScreen] = useState<boolean>(false),
    [OTPId, setOTPId] = useState<string>(""),
    [doShowSecureSmsScreen, setDoShowSecureSmsScreen] = useState<boolean>(false),
    [secureToken, setSecureToken] = useState<string>(""),
    [shortHand, setShortHand] = useState<string>(""),
    [secureSmsError, setSecureSmsError] = useState<string>(""),
    [secureSmsErrCode, setSecureSmsErrCode] = useState<number | null>(null),
    navigate = useNavigate(),
    dispatch = useDispatch();

  useEffect(() => {
    if (getPhoneNumberFromLs() && getAuthTokenFromLs()) {
      navigate(HOME_PAGE, { replace: true });
    }
    updateAddtoHomePopToggle(false);
    dispatch(callLaunchConfigApi({
      callback: function (response: any) {
        if (!checkMinimumScreenSize())
          navigate(IN_COMPATIBLE, { state: { isInvalidScreen: true } });
        if (!checkValidBrowser())
          navigate(IN_COMPATIBLE, { state: { isInvalidScreen: false } });
      }
    }));
  }, []);

  useEffect(() => {
    if (!doShowSecureSmsScreen)
      setSecureSmsErrCode(null);
  }, [doShowSecureSmsScreen]);

  function handleBack() {
    setDoShowOTPScreen(false);
    sendGA4Event({ category: "login", action: "navigate_to_msisdn_page" });
  }

  function fetchGSMBalances() {
    dispatch(
      callCheckGSMBalance(createCheckGSMBalancePayload({ msisdn: getPhoneNumberFromLs(), fetchLoan: loanConfig?.isEnabled }, true))
    );
  }

  const handleOtpFlowSuccess = () => {
    fetchGSMBalances();
    navigate(HOME_PAGE, { replace: true });
  };

  function sendSecureSmsToken() {
    getSecureSMSToken(getPhoneNumberFromLs(), setSecureToken, setShortHand, setDoShowSecureSmsScreen, setSecureSmsErrCode, setSecureSmsError);
  }

  return (
    <div
      className={`font-['Tondo'] login-container h-[100vh] px-20 ${doShowOTPScreen ? "pt-20" : "pt-60"
        }`}
    >
      <div className="bg-[#fff] absolute z-0 top-0 left-0  h-[100vh] w-[100vw] opacity-[0.96]"></div>
      <div className="relative z-1 phoneNumber_wrap">
        {doShowOTPScreen && (
          <>
            <BackButton parentFunc={handleBack} />
            <LoginOTP
              otpId={OTPId}
              setOtpId={setOTPId}
              parentFunc={handleOtpFlowSuccess}
            />
          </>
        )}
        {!doShowOTPScreen && (
          <Login setDoShowOTPScreen={setDoShowOTPScreen} setOTPId={setOTPId} sendSecureSmsToken={sendSecureSmsToken} secureSmsError={secureSmsError} />
        )}
        {doShowSecureSmsScreen && !doShowOTPScreen ?
          <SecureSms closeSecureSms={setDoShowSecureSmsScreen} shortHand={shortHand} secureToken={secureToken} sendSecureSmsToken={sendSecureSmsToken} setOTPId={setOTPId} setDoShowOTPScreen={setDoShowOTPScreen} setApiErrorMsg={() => { }} errorCode={secureSmsErrCode} />
          :
          null
        }
      </div>
    </div>
  );
};

export default LoginFlow;