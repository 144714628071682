export const INITIAL_STATE = "INITIAL_STATE";
export const POLLING_STATE = "POLLING_STATE";
export const TIMEOUT_STATE = "TIMEOUT_STATE";
export const SUCCESS_STATE = "SUCCESS_STATE";
export const LOCKED_STATE = "LOCKED_STATE";
export const FAILED_STATE = "FAILED_STATE";
export const INITIATE_PAGE_POINT_1 = "INITIATE_PAGE_POINT_1";
export const INITIATE_PAGE_POINT_2 = "INITIATE_PAGE_POINT_2";
export const INITIATE_PAGE_POINT_3 = "INITIATE_PAGE_POINT_3";
export const INITIATE_PAGE_POINT_4 = "INITIATE_PAGE_POINT_4";
export const SMS_ACCOUNT_LOCKED = 4196;
export const SMS_MAXIMUM_HIT_CODE = 4198;
export const SMS_TOKEN_VERIFICATION_EXPIRED_CODE = 4199;
export const SMS_NOT_SEND_CODE = 4200;
export const SMS_SENDER_MSISDN_MISMATCH_CODE = 4201;
export const SMS_RECIVER_MSISDN_MISMATCH_CODE = 4202;
export const SMS_INVALID_INPUT_COMBINATION_CODE = 4203;
export const SMS_SOME_ERROR_OCCURED_CODE = 4204;
export const SMS_USER_CHANGED_SMS_CONTENT = 4208;
export const SMS_MSISDN_VERIFICATION_ERROR_CODE = 4205;
export const VERIFICATION_SUCCESS_CODE = 200;
export const SMS_MSISDN_MISMATCH_TEXT = "SMS_MSISDN_MISMATCH_TEXT";
export const SMS_MAX_ATTEMPT_TEXT = "SMS_MAX_ATTEMPT_TEXT";
export const SEND_OTP = "SEND_OTP";
export const SMS_VERIFICATION_FAILED_TEXT = "SMS_VERIFICATION_FAILED_TEXT";
export const OK = "OK";
export const ACCOUNT_LOCKED_TEXT = "ACCOUNT_LOCKED_TEXT";
export const REQUEST_TIMEOUT_HEADING = "REQUEST_TIMEOUT_HEADING";
export const VERIFICATION_FAILED_TEXT = "VERIFICATION_FAILED_TEXT";
export const PROCEED_CTA_TEXT = "PROCEED_CTA_TEXT";
export const MOBILE_VERIFICATION_HEADING = "MOBILE_VERIFICATION_HEADING";
export const VERIFYING_MOBILE_NUMBER = "VERIFYING_MOBILE_NUMBER";
export const VERFYING_TEXT = "VERFYING_TEXT";
export const MOBILE_NUMBER_VERIFIED = "MOBILE_NUMBER_VERIFIED";
export const MOBILE_VERIFIED_TEXT = "MOBILE_VERIFIED_TEXT";
export const PROCEED_TO_SEND_SMS = "PROCEED_TO_SEND_SMS";
export const TIMEOUT_TEXT = "TIMEOUT_TEXT";
export const ACCOUNT_LOCKED_HEADING = "ACCOUNT_LOCKED_HEADING";
export const CLOSE_SECURE_SMS = "close-secure-sms";
export const CALL_SEND_OTP = "call-send-otp";