import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "../../Redux";
import { setOfflinePopupFlag, setOnline } from "../../Redux/slices/globalSlice";
import Loader from "../../components/Loader";
import BlockWebAccess from "../../features/BlockWebAccess";
import useBackgroundBalanceFetch from "../../hooks/useBackgroundBalanceFetch";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import { checkIos, deleteAuthTokenFromLs, getAuthTokenFromLs, getOfflinePopupFlag } from "../../utils/commonUtils";

import blockIcon from "../../assets/icons/ic_ban.svg";
import { getText } from "../../helpers/translateHelper";
import { useTranslations } from "../../hooks/useTranslations";
import { V1, V2 } from "../../data/constants";
import useLockScrollOnInputFocus from "../../hooks/useLockScrollOnInputFocus";
import useDelayedFunction from "../../hooks/useDelayedFunction";
import { callFetchTransactionsFailed, setMaskedAmBalance, setMaskedAmTransaction } from "../../Redux/slices/amSlice";
import { callLogout } from "../../Redux/slices/appSettingsSlice";
import { useIdle } from "../../hooks/useIdleTimer";
import { APP_SETTINGS_ROUTE, HOME_PAGE, LOG_OUT, SUCCESS_V2 } from "../../routes/routeConstants";
import { Skeleton } from "../../components/SkeletonCard";
import ErrorBoundaryUI from "../../components/ErrorBoundary/ErrorBoundaryUI";
import { callFetchTranslations } from "../../Redux/slices/translationSlice";
import { callLaunchConfigApi } from "../../Redux/slices/configSlice";
import RenderIosAthsScreen from "../../components/ShowIOSAuthScreen";
import AddToHomePopup from "../../components/AddToHomePopUp";
import NoInternetModal from "../../components/NoInternetModal/NoInternetModal";
import OfflineStripComponent from "../../components/OfflineStripComponent/OfflineStripComponent";
import { checkIsBetaFeatureAvailable } from "../../features/beta/helpers/betaHelper";
import { isMobileDevice } from "../../utils/commonUtils";
import { toggleShowIOSScreenStatus } from "../../helpers/commonHelpers";

interface Props {
  children: any;
}

const HomeLayout: React.FC<Props> = (props) => {
  const isLoading = useSelector((state: RootState) => state.globalStore?.isLoading),
    isAmMasked = useSelector((state: RootState) => state?.balance?.balance?.isAmMasked),
    amUnmaskedTimeStamp = useSelector((state: RootState) => state.amStore.amUnmaskedTimeStamp),
    isAMTranscationMasked = useSelector((state: RootState) => state.amStore.isAMTranscationMasked),
    amTransactionTimestamp = useSelector((state: RootState) => state.amStore.amTransactionTimestamp),
    isOnline = useSelector((state: RootState) => state?.globalStore?.isOnline),
    offlinePopupFlag = useSelector((state: RootState) => state?.globalStore?.offlinePopupFlag),
    { isEnable = false, betaCode = '' } = useSelector((state: RootState) => state?.config?.launchConfig?.offlineConfig) ?? {},

    launchConfig = useSelector((state: RootState) => state.config.launchConfig) || {},
    apiError = useSelector((state: RootState) => state.globalStore.apiError),
    version = launchConfig.version,
    autoLogout = launchConfig.autoLogout,
    navigate = useNavigate(),
    dispatch = useDispatch(),
    location = useLocation(),
    { translationsAreInit, translationError } = useTranslations(),
    openAddToHomePopUp = useSelector((state: RootState) => state.globalStore?.openAddToHomePopUp),
    windowObj: any = window;
  let timerId: any = null;

  useOnlineStatus();

  useLockScrollOnInputFocus();
  useEffect(() => {
    if (version === V1) {
      document.body.style.backgroundColor = `#212121`
    }
    else {
      document.body.style.backgroundColor = `#FAFAFA`
      document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
          if (getAuthTokenFromLs() && process.env.NODE_ENV === "production" && autoLogout) {
            dispatch(callLogout({
              hideLoader: true,
              callback: () => {
                deleteAuthTokenFromLs();
                navigate(LOG_OUT, { replace: true });
              }
            }))
          }
        }
      }, false);
    };
    windowObj?.webengage?.init(process.env.REACT_APP_WEBENGAGE_ID)
    return (() => {
      if (timerId) {
        clearInterval(timerId);
      }
      document.removeEventListener("visibilitychange", function () { });
    })
  }, []);


  useEffect(() => {
    if (!!windowObj?.navigator?.onLine) {
      dispatch(setOnline(true));
    }
  }, [windowObj.navigator.onLine])

  useEffect(() => {
    if (!isAmMasked) {
    }
  }, [isAmMasked])

  const handleIdle = () => {
    if (window.location.pathname !== "/" && getAuthTokenFromLs()) {
      dispatch(callLogout({
        callback: () => {
          deleteAuthTokenFromLs();
          navigate(LOG_OUT, { replace: true });
        }
      }))
    }
  };

  function refreshFunc() {
    if (apiError?.errorBy?.includes("launch-config"))
      dispatch(callLaunchConfigApi({}));
    else
      dispatch(callFetchTranslations({}));
  }

  useDelayedFunction(1000 * 60 * 5, amUnmaskedTimeStamp, () => dispatch(setMaskedAmBalance(true)), isAmMasked);
  useDelayedFunction(1000 * 60 * 5, amTransactionTimestamp, () => { dispatch(setMaskedAmTransaction(true)); dispatch(callFetchTransactionsFailed({})) }, isAMTranscationMasked);
  useIdle({ onIdle: handleIdle });

  const handleClose = (isCloseClicked=false) => {
    if (location.pathname === APP_SETTINGS_ROUTE) {
      dispatch(callLogout({
        callback: (res: any) => {
          deleteAuthTokenFromLs()
          navigate(LOG_OUT, { replace: true });
        }
      }));
    } else if (location.pathname === SUCCESS_V2) {
      navigate(HOME_PAGE, { replace: true })
      !isCloseClicked && checkIos() && toggleShowIOSScreenStatus(true);
    }
  }

  const handleCloseNoInternetModal = () => {
    if (getOfflinePopupFlag()) {
      navigate(HOME_PAGE, { replace: true })
    } else {
      dispatch(setOfflinePopupFlag(false))
    }
  }

  const isNoInternetModalFlag = () => {
    return !isOnline && isEnable && checkIsBetaFeatureAvailable(betaCode) && (getOfflinePopupFlag() || offlinePopupFlag)
  }

  return (
    <div style={{ backgroundColor: "#FAFAFA" }} className={`layout-container w-[100vw] ${version === V2 ? "bg-[#FAFAFA]  h-full" : "overflow-y-hidden bg-primaryBlack h-[100svh]"}`}>
      {!isOnline && isEnable ? <OfflineStripComponent /> : null}
      {translationsAreInit ? <>
        {!isMobileDevice() ? <BlockWebAccess /> : props.children}
        <div className="block-landscape hidden bg-black w-[100vw] h-[100vh] fixed top-0 bottom-0 right-0 left-0">
          <div className="landscape-text text-white font-bold flex justify-center items-center h-full text-center flex-col">
            <div className="icon mb-30">
              <img src={blockIcon} alt="block-icon" />
            </div>
            {getText("LANDSCAPE_MODE_MESSAGE1")}<br /> {getText("LANDSCAPE_MODE_MESSAGE2")}
          </div>
        </div>
      </>
        :
        <Skeleton getskeletonLogin={true} />
      }
      {!!apiError?.isApiError &&
        < ErrorBoundaryUI refreshFunc={refreshFunc} />
      }
      {isLoading ?
        <Loader />
        :
        null
      }
      <AddToHomePopup isOpen={openAddToHomePopUp} onClose={handleClose} from={location.pathname} />
      <RenderIosAthsScreen />
      {isNoInternetModalFlag() ? <NoInternetModal handleRefresh={handleCloseNoInternetModal} /> : null}
    </div>
  )
}

export default HomeLayout;