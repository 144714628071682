import { useState } from "react";
import { RootState } from "../../../Redux";
import { useDispatch, useSelector } from "react-redux";
import { webLandingPageIcns } from "../../../config/icons";
import { SUCCESS_STATUS_CODE, MSISDN_LENGTH_COMPLETE_TEXT, APPLITE_TEXT } from "../../../data/constants";
import { LANDING_PAGE_TEXT } from "../constants";
import Button from "../../../components/Button/Button";
import { getText } from "../../../helpers/translateHelper";
import { sendAirtelLiteLink } from "../../../Redux/slices/globalSlice";
import ic_green_tick from "../../../assets/icons/successful.svg";
import infoIcn from "../../../assets/icons/info.svg";
import { checkMsisdnIsValid } from "../../../utils/commonUtils";
import { SendPWALinkResponceType } from "../../../config/landingPageInterface";
import { sendGA4Event } from "../../../services/ga4";
import { LANDING_PAGE_EVENTS } from "../../../data/eventActions";
import { eventCategory } from "../../../data/events";

const UserInputComponent = () => {
  const [userInput, setUserInput] = useState<string | number>('');
  const [msg, setMsg] = useState<string>('');
  const [successFlag, setSuccessFlag] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);

  const dispatch = useDispatch();
  const countryCode = useSelector((state: RootState) => state.config?.launchConfig?.phoneCode);
  const { SCAN_QR, SCAN_QR_DESCRIPTION, LANDING_PAGE_ENTER_NUMBER, ENTER_AIRTEL_NUMBER_TEXT, GET_LINK, LANDING_PAGE_INPUT_PLACEHOLDER } = LANDING_PAGE_TEXT;

  const oppcoName = process.env.REACT_APP_OPCO;

  const sendEvents = (action: string) => {
    sendGA4Event({ category: eventCategory.LANDING_PAGE, action: action });
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const checkInputNumber = checkMsisdnIsValid(value)
    msg && setMsg('')
    if (checkInputNumber === MSISDN_LENGTH_COMPLETE_TEXT) {
      setUserInput(value)
      setDisable(false)
    } else if (checkInputNumber) {
      setUserInput(value)
      !disable && setDisable(true)
    }
  }

  const handleClick = () => {
    const { APP_LITE_LINK_SENDS_SUCCESS, APP_LITE_LINK_SENDS_FAILED } = LANDING_PAGE_EVENTS
    const payload = {
      data: {
        msisdn: userInput,
        channel: APPLITE_TEXT,
      },
      callback: (response: SendPWALinkResponceType) => {
        const isSuccess = response?.data?.statusCode === SUCCESS_STATUS_CODE ? true : false;
        sendEvents(isSuccess ? APP_LITE_LINK_SENDS_SUCCESS : APP_LITE_LINK_SENDS_FAILED)
        setSuccessFlag(isSuccess)
        setMsg(response?.data?.message);
      }
    }
    
    dispatch(sendAirtelLiteLink(payload))
    sendEvents(LANDING_PAGE_EVENTS.USER_ENTERS_MSISDN)
  }

  return (
    <div className="flex flex-row gap-32">
      <div className="flex flex-col bg-white  scan-qr-contsiner  border border-[#E8E8E8] rounded-[1.5rem] p-10 px-30 gap-10 w-[35%]">
        <div className="text-12 font-medium">
          {getText(SCAN_QR)}
        </div>
        <div className="flex align-middle justify-center h-[96px] w-[96px] self-center">
          <img src={webLandingPageIcns[`airtelQR${oppcoName}` as keyof typeof webLandingPageIcns]} height={'96px'} width={'96px'} alt='airtel lite link qr code' />
        </div>
        <div className="text-10">
          {getText(SCAN_QR_DESCRIPTION)}
        </div>
      </div>
      <div className="flex bg-white flex-col border border-[#E8E8E8] rounded-[1.5rem] p-20 gap-10  w-[65%]">
        <div className="text-12 font-medium">
          {getText(LANDING_PAGE_ENTER_NUMBER)}
        </div>
        <div className="text-10 px-30">
          {getText(ENTER_AIRTEL_NUMBER_TEXT)}
        </div>
        <div className="phone-number-input relative">
          <span className={`absolute text-12  top-8 left-16 pointer-events-none  border-E8E8E8 text-secondaryBlack"}`}>
            {countryCode}
          </span>
          <input
            className={`outline-0 mobile-input-box  border pl-48 rounded-[2rem] bg-transparent  w-full px-20 pt-7 pb-5 text-[12px] focus:placeholder-transparent border-[${msg && !successFlag ? '#FF0000' : '#E8E8E8'}] placeholder-[#1f24364d] text-[${msg && !successFlag ? '#FF0000' : 'black'}]`}
            onChange={handleInputChange}
            placeholder={getText(LANDING_PAGE_INPUT_PLACEHOLDER)}
            value={userInput}
          />
          {msg ? <div className={`flex flex-row gap-5 text-[8px] pt-2 pl-5`}>
            <img src={successFlag ? ic_green_tick : infoIcn} className="h-[10px] w-[10px]" />
            <div className={`text-[${successFlag ? 'black' : '#FF0000'}]`}>{msg}</div>
          </div>
            : null}
        </div>
        <Button handleClick={handleClick} text={getText(GET_LINK)} isDisable={disable} />
      </div>
    </div>
  )
}

export default UserInputComponent