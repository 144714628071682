import moment from "moment";
import "moment/locale/fr";
import "moment/locale/sw";
import { GLOBAL } from "../data/globals";
import { store } from "../Redux";
import { resetCheckLobData, resetHistoryData, resetRecentNumberName, saveAthsEvent, setApiError, setLoader, updateAddToHomePopUp, updateShowIOSScreen } from "../Redux/slices/globalSlice";
import * as codes from "../config/codes";
import { HOME_PAGE } from "../routes/routeConstants";
import { BETA_FEATURES, CASHOUT, P2P, P2PI, PREPAID_BUY_BUNDLES, PREPAID_RECHARGE, DATE_FORMAT } from "../data/constants";
import { callGetAMUserProfile } from "../Redux/slices/homeSlice";
import { getIsGuestUser, getPhoneNumberFromLs, getOpcoDefaultLang, getUserLang } from "../utils/commonUtils";
import { checkIsBetaFeatureAvailable } from "../features/beta/helpers/betaHelper";

export const getVersionDefaultPath = () => {
  return HOME_PAGE;
};

export const getLoaderRunningStatus = () => {
  let globalStore = store.getState()?.globalStore || {};
  return globalStore.isLoading;
};

export const startLoader = () => {
  store.dispatch(setLoader(true));
};

export const stopLoader = () => {
  store.dispatch(setLoader(false));
};

export const storeAthsEvent = (event: any) => {
  store.dispatch(saveAthsEvent(event));
};

export const getAthsEvent = () => {
  return store.getState().globalStore.athsEvent;
};

export const getTime = (date: Date) => {
  console.log(moment().format("DD/MM/YYYY, h:mm a"));
  return moment(date).format("DD/MM/YYYY, h:mm a");
};

// get error message for an api code
export const getErrorMsg = (
  code: string,
  isAMBarred: boolean | undefined
): string => {
  if (
    [
      codes.featureNames.RECHARGE_AIRTIME,
      codes.featureNames.SEND_MONEY,
      codes.balanceState.AIRTEL_MONEY_PIN,
    ].includes(code) &&
    Boolean(isAMBarred)
  ) {
    return "AM_BARRED";
  } else return "";
};


export const getOnlineStatus = () => {
  return store.getState()?.globalStore?.isOnline;
}

//Dvice/OS/Browser Detect
export const getDeviceInfo = () => {
  const windowObj: any = window;
  let module = {
    options: [],
    header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, windowObj.opera],
    dataos: [
      { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
      { name: 'Windows', value: 'Win', version: 'NT' },
      { name: 'iPhone', value: 'iPhone', version: 'OS' },
      { name: 'iPad', value: 'iPad', version: 'OS' },
      { name: 'Kindle', value: 'Silk', version: 'Silk' },
      { name: 'Android', value: 'Android', version: 'Android' },
      { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
      { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
      { name: 'Macintosh', value: 'Mac', version: 'OS X' },
      { name: 'Linux', value: 'Linux', version: 'rv' },
      { name: 'Palm', value: 'Palm', version: 'PalmOS' }
    ],
    databrowser: [
      { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
      { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
      { name: 'Safari', value: 'Safari', version: 'Version' },
      { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
      { name: 'Opera', value: 'Opera', version: 'Opera' },
      { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
      { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
    ],
    init: function () {
      let agent = this.header.join(' '),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);
      return { os: os, browser: browser };
    },
    matchItem: function (string: string, data: any) {
      let i = 0,
        j = 0,
        html = '',
        regex,
        regexv,
        match,
        matches: any,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) { if (matches[1]) { matches = matches[1]; } }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return { name: 'unknown', version: 0 };
    }
  };

  let e = module.init();
  return {
    "OSName": e.os.name,
    "OSVersion": e.os.version,
    "BrowserName": e.browser.name,
    "BrowserVersion": e.browser.version,
    "userAgent": navigator.userAgent,
    "platform": navigator.platform,
  };
}

export const checkIsOnline = () => {
  const isOnline = store.getState()?.globalStore?.isOnline || false;
  return isOnline;
}

//This function check the time diffrence and if time difference is less than 4 hours it gives false
export const checkValidTimeDifference = (timeStamp: string = "", timeout: number = 15) => {
  if (timeStamp) {
    let now = moment(new Date()), //todays date
      end = moment(timeStamp), // another date
      duration = moment.duration(now.diff(end)),
      minutes = duration.minutes();
    return (minutes >= timeout);
  }
  return true;
}

export const resetData = () => {
  store.dispatch(resetCheckLobData({}));
  store.dispatch(resetHistoryData({}));
  store.dispatch(resetRecentNumberName({}));
}

export const checkValidValue = (regex: RegExp, value: any) => {
  if (regex) {
    regex = new RegExp(regex);
    return regex?.test(value);
  }
  return true;
}

export const filterDataByKey = (data: any = [], key: string = "") => {
  let result = [];
  result = orderBy(data, [(obj) => obj[key] || ''], ['desc']);
  return result;
}

export function addSpaceInMsisdn(msisdn: string = "") {
  // Use a regular expression to match the first three letters
  const regex = /^(.{3})/

  // Insert a space after the first three letters
  const resultString = msisdn.replace(regex, '$1 ');

  return resultString;
}

export function isPinset() {
  let amProfile: any = store.getState().home.amProfile,
    flag = !amProfile?.pinSet || amProfile.pinSet === 'true';
  return flag;
}

export function isQuestionSet() {
  let amProfile: any = store.getState().home.amProfile,
    flag = amProfile?.recoveryQuestionSet == 'true';
  return flag;
}

export function isResetPinEnable() {
  let amProfile: any = store.getState().home.amProfile,
    flag = (amProfile.pinReset === 'true' && amProfile.pinSet === 'true');
  return flag;
}

export function isResetPin() {
  let amProfile: any = store.getState().home.amProfile,
    flag = isBoolean(amProfile?.pinReset) ? amProfile?.pinReset : amProfile.pinReset === 'true';
  return flag;
}

export function isAMBarred() {
  let amProfile: any = store.getState().home.amProfile,
    flag = amProfile?.userBarred === 'true' || amProfile?.userBarred === true;
  return flag;
}

export function isAmAccountActive() {
  const amAccountActive: any = store.getState().home.amProfile?.accountStatus?.isActive;
  return amAccountActive === false ? amAccountActive : true;
}

export function isTransactionLimitAllow(type: string) {
  const transactionLimtConfig = store.getState().config.launchConfig.transactionLimConfig;
  const transactionLimtConfigForGuest = store.getState().config.launchConfig?.guestConfig?.transactionLimConfig;

  let status = false;
  if (getIsGuestUser()) {
    if (transactionLimtConfigForGuest?.IS_ENABLED) {
      if (type === PREPAID_RECHARGE) {
        status = transactionLimtConfigForGuest?.PREPAID_RECHARGE;
      }
    }
  } else if (transactionLimtConfig?.IS_ENABLED) {
    switch (type) {
      case PREPAID_BUY_BUNDLES:
        status = transactionLimtConfig?.PREPAID_BUY_BUNDLES ?? false;
        break;
      case PREPAID_RECHARGE:
        status = transactionLimtConfig?.PREPAID_RECHARGE ?? false;
        break;
      case P2P:
        status = transactionLimtConfig?.P2P ?? false;
        break;
      case P2PI:
        status = transactionLimtConfig?.P2PI ?? false;
        break;
      case CASHOUT:
        status = transactionLimtConfig?.CASHOUT ?? false;
        break;
      default: break;
    }
  }
  return status
}

export const isArray = (value: any) => {
  return Object.prototype.toString.call(value) === "[object Array]";
}

export function handleMsisdnPaste(e: React.ClipboardEvent<HTMLInputElement>, handleFunc: any) {
  let msisdn = "";
  const msisdnLength = store.getState().config?.basicConfig?.msisdnLength;
  const pastedData = e.clipboardData
    .getData('text/plain')
    .trim();
  msisdn = pastedData?.replaceAll(/\s/g, ''); //remove whitespace
  const length = Math.max(msisdn.length - +msisdnLength);
  msisdn = msisdn.substring(length);
  handleFunc(msisdn);
}

export const isLockedUser = () => {
  const lockInfo: any = store.getState().home.amProfile?.lockInfo;
  let isLocked = false;
  if (lockInfo?.isLocked) {
    if (lockInfo?.lockedBy === 'INVALID_DOCUMENT_ID') {
      if (new Date().getTime() < new Date(lockInfo?.lockExpiry).getTime()) isLocked = true
    } else if (lockInfo?.lockedBy === 'INVALID_SECURITY_ANSWER') isLocked = true
  }
  return isLocked;
}

export const setApiErrorStatus = (flag: boolean = false, errorBy: string = "") => {
  store.dispatch(setApiError({ isApiError: flag, errorBy }));
}

export const fetchAmProfile = () => {
  let amUserProfilePayload = {
    data: { msisdn: getPhoneNumberFromLs() },
    hideLoader: true,
  };
  store.dispatch(callGetAMUserProfile(amUserProfilePayload));
}
export const isPayXEnabled = () => {
  const payXEnabled: any = store.getState()?.config?.launchConfig?.payXEnabled;
  return payXEnabled;
}

export const updateAddtoHomePopToggle = (isOpen = false) => {
  store.dispatch(updateAddToHomePopUp({ openAddToHomePopUp: isOpen }));
}

export const getBundleWithNearestExpiry = (bundles: any = []) => {
  // Find the bundle with the nearest expiry date
  return bundles.reduce((nearestBundle: any, item: any) => {
    const expiryDate = new Date(item.expiry);  // Convert expiry date to Date object

    // If no nearest bundle or the current expiry is earlier than the nearest one
    if (!nearestBundle || expiryDate < new Date(nearestBundle.expiry)) {
      return item;
    }
    return nearestBundle;
  }, null);
}

export const showQsg = () => {
  const userConfig = store.getState()?.home?.userInfo?.userConfig;
  const enableQsg = store.getState()?.config?.launchConfig?.enableQsg;
  return ((userConfig?.firstLoginPostQSG || userConfig?.showQsgAfterInterval) && checkIsBetaFeatureAvailable(BETA_FEATURES.QSG) && enableQsg && !getIsGuestUser());
}

export const blurActiveElement = () => {
  if (document.activeElement instanceof HTMLElement)
    document?.activeElement?.blur();
}

export const getValType = (val: any) => {
  return typeof val;
}

export const getSize = (val: object | string): number => {
  const valType = getValType(val);
  if (val) {
    if (valType === GLOBAL.OBJECT) {
      return Object.keys(val).length;
    } else if (valType === GLOBAL.STRING) {
      return (val as String).length;
    }
  }
  return 0;
}

export const isObject = (val: any): boolean => {
  if (val && (getValType(val) === GLOBAL.OBJECT))
    return true;
  else
    return false;
}

export const isEmpty = (val: any): boolean => {
  const valType = getValType(val);
  if (valType === GLOBAL.OBJECT) {
    return getSize(val) === 0;
  } else if (valType === GLOBAL.STRING) {
    return getSize(val) === 0;
  }
  return true;
}

export const isBoolean = (val: any): boolean => {
  if (getValType(val) === GLOBAL.BOOLEAN || getValType(val?.valueOf()) === GLOBAL.BOOLEAN) {
    return true;
  }
  return false;
}

export const sortBy = <T>(array: T[] = [], iteratees: keyof T | ((item: T) => any) | Array<keyof T | ((item: T) => any)>): T[] => {
  const iterateesType = getValType(iteratees);
  if (!Array.isArray(array)) {
    return [];
  }

  if (!iteratees || (iterateesType !== GLOBAL.STRING && iterateesType !== GLOBAL.FUNCTION && !Array.isArray(iteratees))) {
    return array;
  }

  const toPath = (path: keyof T | (keyof T)[]): (keyof T)[] => Array.isArray(path) ? path : [path];

  const getValue = (obj: any, path: keyof T | (keyof T)[]): any => toPath(path).reduce((acc, key) => (acc ? acc[key] : undefined), obj);

  const normalizeValue = (value: any): any => {
    if (value && getValType(value.valueOf) === GLOBAL.FUNCTION) {
      return value.valueOf();
    }
    return value;
  };

  const comparator = (a: T, b: T, iteratee: keyof T | ((item: T) => any)): number => {
    const iterateeType = getValType(iteratee);
    const aValue = normalizeValue(
      iterateeType === GLOBAL.FUNCTION ? (iteratee as Function)(a) : getValue(a, (iteratee as keyof T | (keyof T)[]))
    );
    const bValue = normalizeValue(
      iterateeType === GLOBAL.FUNCTION ? (iteratee as Function)(b) : getValue(b, (iteratee as keyof T | (keyof T)[]))
    );

    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  };

  return [...array].sort((a, b) => {
    if (Array.isArray(iteratees)) {
      for (const iteratee of iteratees) {
        const result = comparator(a, b, iteratee);
        if (result !== 0) return result;
      }
      return 0;
    } else {
      return comparator(a, b, iteratees);
    }
  });
};

export const orderBy = <T>(array: T[] = [], iteratees: keyof T | ((item: any) => any) | Array<keyof T | ((item: any) => any)>, orders: 'asc' | 'desc' | Array<'asc' | 'desc'>): T[] => {
  const iterateeArray = Array.isArray(iteratees) ? iteratees : [iteratees];
  const ordersArray = Array.isArray(orders) ? orders : [orders];
  const iterateeLength = getSize(iterateeArray);

  const getValue = (item: T, iteratee: keyof T | ((item: T) => any)) => {
    if (getValType(iteratee) === GLOBAL.FUNCTION) {
      return (iteratee as Function)(item);
    }
    return item[iteratee as keyof T];
  };

  const compare = (a: T, b: T, index: number = 0): number => {
    if (index >= iterateeLength) return 0;

    const iteratee = iterateeArray[index];
    const order = ordersArray[index] === GLOBAL.DESC ? -1 : 1;
    const valA = getValue(a, iteratee);
    const valB = getValue(b, iteratee);

    if (valA < valB) return -1 * order;
    if (valA > valB) return 1 * order;

    return compare(a, b, index + 1);
  };

  return [...array].sort((a, b) => compare(a, b));
};

export const debounce = (func: (...args: any[]) => void, wait: number, immediate: boolean = false): (...args: any[]) => void => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (...args: any[]) => {
    const later = () => {
      timeout = null;
      if (!immediate)
        func(...args);
    };
    const callNow = immediate && !timeout;
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
    if (callNow)
      func(...args);
  };
};

export const trimValue = (val: string): string => {
  return val.trim();
}

export const toggleShowIOSScreenStatus = (showIOSScreen = false) => {
  store.dispatch(updateShowIOSScreen({ showIOSScreen: showIOSScreen }));
}

export const getParsedDate = (timestamp: number|string|undefined = undefined, format: string = DATE_FORMAT) => {
  const lang = getUserLang() || getOpcoDefaultLang();
  return moment(timestamp).locale(lang).format(format)
}