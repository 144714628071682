import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../Redux";
import { callGetLOB } from "../../../../Redux/slices/globalSlice";
import { PrefixedMsisdn } from "../../../../config/commonInterfaces";
import { createGetLOBPayload } from "../../../../features/login";
import { getText } from "../../../../helpers/translateHelper";
import { sendGA4Event } from "../../../../services/ga4";
import { parseMsisdn, removePrefix, removeZeroFromStart, setIsGuestUser, setPhoneNumberToLs } from "../../../../utils/commonUtils";
import { PhoneNumber } from "../PhoneNumber";
import { isLoginMsisdnsLenComplete, isLoginMsisdnsLenInLimit } from "../../helpers/loginHelper";
import { checkIsOnline, resetData } from "../../../../helpers/commonHelpers";
import { isSecureSmsSupported, sendOtp } from '../../helpers/loginHelper';
import Bowser from "bowser";
import { IS_GUEST_USER, OFFLINE_MESSAGE, fallbackTranslations } from "../../../../data/constants";
import { getOS } from "../../../../utils/browserDetails";
import { HOME_PAGE } from "../../../../routes/routeConstants";
import { clearLocalStorage, removeSessionStorage } from "../../../../utils/localStorage";
import { useText } from "../../../../hooks/useText"

interface ILoginProps {
  setDoShowOTPScreen: (val: boolean) => void,
  setOTPId: (val: string) => void;
  sendSecureSmsToken: () => void;
  secureSmsError: string;
}

export function Login({ setDoShowOTPScreen, setOTPId, sendSecureSmsToken, secureSmsError }: ILoginProps) {
  const [prefixedPhoneNumber, _setPhoneNumber] = useState<PrefixedMsisdn>({ value: "" }),
    [apiErrorMsg, setApiErrorMsg] = useState(""),
    msisdnLength = useSelector((state: RootState) => state?.config?.launchConfig?.msisdnLength) || "9",
    dispatch = useDispatch(), navigate = useNavigate();
  const enableSecureSms = useSelector((state: RootState) => state.config?.launchConfig?.enableSecureSms);
  const secureSmsNonSupportedBrowsers = useSelector((state: RootState) => state.config?.launchConfig?.secureSmsNonSupportedBrowsers);
  const isGuestLoginIsEnabled = useSelector((state: RootState) => state.config?.launchConfig?.guestConfig?.isEnabled);

  const setPhoneNumber = (value: string) => _setPhoneNumber({ ...prefixedPhoneNumber, value });
  //msisdn without prefix
  const rawMsisdn = removePrefix(prefixedPhoneNumber);
  useText();

  useEffect(() => {
    const os = getOS();
    const browser = Bowser.getParser(window?.navigator?.userAgent)?.getBrowser();
    sendGA4Event({ category: "login", action: "login_screenviewed", data: { "browser_name": browser?.name || "", "browser_version": browser.version || "", "os_details": os?.name || "", "os_version": os.version || "", "os_versionName": os.versionName || "", "screen_size": `${window?.screen?.width}*${window?.screen?.height}` } });
    clearLocalStorage();
    removeSessionStorage(IS_GUEST_USER);
    resetData();
  }, []);

  useEffect(() => {
    if (secureSmsError)
      setApiErrorMsg(secureSmsError);
  }, [secureSmsError]);

  const handleSubmit = () => {
    setApiErrorMsg("");
    if (rawMsisdn) {
      if (enableSecureSms && isSecureSmsSupported(secureSmsNonSupportedBrowsers)) proceedSecureSms()
      else proceedSendOtp()
    }
  }

  const proceedSendOtp = () => {
    sendGA4Event({ category: "login", action: "login_otp_otprequestinitiated" });
    sendOtp(rawMsisdn, setApiErrorMsg, setDoShowOTPScreen, setOTPId);
    setPhoneNumberToLs(rawMsisdn);
  }

  const proceedSecureSms = () => {
    // open popup for secure sms
    console.log('secure sms trigger');
    sendSecureSmsToken();
  }

  const createLOBPayload = (_rawMsisdn: string) => createGetLOBPayload(parseMsisdn(_rawMsisdn),
    (isAirtel, isPostpaidNum, isBroadband) => {
      let isValid = isAirtel && !isPostpaidNum && !isBroadband;

      if (isValid) {
        setApiErrorMsg("");
        sendGA4Event({ category: "login", action: "msisdn_is_gsm" });
        sendGA4Event({ category: "login", action: "navigate_to_otp_page" });
      }
      else if (!isAirtel) {
        setApiErrorMsg(getText("PLEASE_ENTER_AIRTEL_NUM"));
        sendGA4Event({ category: "login", action: "invalid_phone_number" });
        setDoShowOTPScreen(false);
      }
      else if (isBroadband || isPostpaidNum) {
        setApiErrorMsg(getText("PLEASE_ENTER_PREPAID_NUM"));
        if (isPostpaidNum)
          sendGA4Event({ category: "login", action: "msisdn_is_postpaid" });
        if (isBroadband)
          sendGA4Event({ category: "login", action: "msisdn_is_hbb" });
        sendGA4Event({ category: "login", action: "invalid_phone_number" });
        setDoShowOTPScreen(false);
      }
    },
    (errorMsg) => {
      if (!checkIsOnline()) {
        setApiErrorMsg(getText(fallbackTranslations[OFFLINE_MESSAGE]));
        return
      }
      setApiErrorMsg(errorMsg || getText("SOME_ERR"));
      setDoShowOTPScreen(false);
    }
  )

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {


    const value = event.target.value;
    if (!isLoginMsisdnsLenInLimit(value, +msisdnLength) || !/^(?!00)[0-9]*$/.test(value))
      return;
    setPhoneNumber(value);
    setApiErrorMsg("");

    if (isLoginMsisdnsLenComplete(value, +msisdnLength)) {

      const _rawMsisdn = removeZeroFromStart(value);
      setPhoneNumberToLs(_rawMsisdn);
      dispatch(callGetLOB(createLOBPayload(_rawMsisdn)));
      sendGA4Event({ category: "login", action: "login_otp_msisdnentered" });
    }
  }

  const handleGuestLogin = () => {
    setIsGuestUser(true);
    navigate(HOME_PAGE, { replace: true })
    sendGA4Event({ category: "login", action: "login_guest_continue_clicked" })
  }

  return <>
    <div className="text-16 font-medium login-heading text-secondaryBlack"> {getText("LOGIN_HEADING")} </div>
    <div className="phone-number-wrapper">
      <PhoneNumber parentErrorMsg={apiErrorMsg} updateValue={handlePhoneNumberChange} setParentErrorMsg={setApiErrorMsg}
        handleSubmitProp={handleSubmit} prefixedMsisdn={prefixedPhoneNumber} />
      {isGuestLoginIsEnabled &&
        <>
          <div className="mt-8 w-full font-medium text-center text-14">
            {getText("OR")}
          </div>
          <button className="send-otp-btn mt-8 w-full bg-whiteSubmitButton text-rose-600 border border-solid border-rose-600 font-medium text-center text-14 py-10 rounded-md"
            onClick={handleGuestLogin}>
            {getText("CONTINUE_WITHOUT_LOGIN")}
          </button>
          <div className="flex justify-center pt-[10px] text-[12px] text-center">{getText("LOGIN_RECOMMENDATION_TEXT")}</div>
        </>}
    </div>
  </>
}
