import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createResendOtpPayload, createVerifyOtpPayload } from "../..";
import { RootState } from "../../../../Redux";
import { callResendOtp, callVerifyOtp } from "../../../../Redux/slices/loginSlice";
import OTPInput from "../../../../components/OtpInput";
import { OTP_LENGTH, headings } from "../../../../data/constants";
import { sendGA4Event } from "../../../../services/ga4";
import { getPhoneNumberFromLs, removeZeroFromStart, setAuthTokenToLs } from "../../../../utils/commonUtils";
import { getText } from "../../../../helpers/translateHelper";
import { addSpaceInMsisdn } from "../../../../helpers/commonHelpers";

interface ILoginOTPProps {
  otpId: string,
  parentFunc: () => void;
  setOtpId: (newOtpId: string) => void;
}

//component renders otp screen, performs api calls
const LoginOTP = ({ otpId, setOtpId, parentFunc }: ILoginOTPProps) => {
  const phoneNumber = getPhoneNumberFromLs() || "";
  const defaultTimer = Number(useSelector((state: RootState) => state?.config?.launchConfig?.otpTimer));
  const [otp, setOtp] = useState<string>(""),
    [apiMsg, setApiMsg] = useState(""),
    [otpattemptLeft, setOtpAttemptLeft] = useState(""),
    [timer, setTimer] = useState<number>(Number(defaultTimer)),
    [canResendOtp, setCanResendOtp] = useState<boolean>(false),
    [showResendOtp, setShowResendOtp] = useState<boolean>(true),
    [isReset, setReset] = useState<boolean>(false),
    loginData = useSelector((state: RootState) => state.login),
    dispatch = useDispatch();

  const timerID = useRef<NodeJS.Timer>();


  useEffect(() => {

    startTimer();
    return () => {
      clearInterval(timerID.current);
    }
  }, []);

  useEffect(() => {
    if (otp.length === OTP_LENGTH)
      handleOtpSubmit();
  }, [otp]);

  const handleOtp = (value: any) => {
    setOtp(value);
    setApiMsg("");
    setOtpAttemptLeft("");
  }

  const startTimer = () => {
    if (timerID.current) {
      clearInterval(timerID.current);
      timerID.current = undefined;
    }

    let seconds = defaultTimer;
    timerID.current = setInterval(() => tick(--seconds), 1000);
  }

  const tick = (seconds: number) => {

    if (seconds > 0) {
      setTimer(seconds);
    } else { //reset timer
      clearInterval(timerID.current);
      timerID.current = undefined;
      setCanResendOtp(true);
    }
  }

  const verifyOtp = () => {
    dispatch(callVerifyOtp(createVerifyOtpPayload(otp, otpId, phoneNumber, (response: any) => {
      if (response?.statusCode === 200) {
        setAuthTokenToLs(response?.result?.access_token);
        // sendGA4Event({ category: "login", action: "verify_otp_success"});
        sendGA4Event({ category: "login", action: "login_otp_otpvalidated_successful" });
        parentFunc();
      }
      else {
        if (response?.statusCode === 5001) {
          sendGA4Event({ category: "login", action: "login_otp_otpvalidated_unsuccesful" });
          const message = `${response?.message}`;
          const attemptsLeft = response?.result.remainingAttemptsLeft;
          setApiMsg(attemptsLeft ? message : getText("MAX_ATTEMPT_EXHAUSTED_30"));
          setOtpAttemptLeft(attemptsLeft ? response?.result.remainingAttemptsLeft : "");
          setShowResendOtp(true);
          if (!attemptsLeft)
            sendGA4Event({ category: "login", action: "max_attempts_exhausted" });

          return;
        }
        else if (response?.statusCode === 5002) {
          let message = `${response?.message}`;
          setApiMsg(message);
          setCanResendOtp(true);
          setShowResendOtp(true);
          setTimer(defaultTimer);
          startTimer();
          sendGA4Event({ category: "login", action: "verify_otp_expired" });
          return
        }
        else {
          setApiMsg(response?.message);
          setCanResendOtp(true);
          setShowResendOtp(false);
          setOtpAttemptLeft("");
          clearInterval(timerID.current);
          if (response?.statusCode === 1025)
            sendGA4Event({ category: "login", action: "max_attempts_exhausted" });
        }
        // sendGA4Event({ category: "login", action: "verify_otp_success"});
        // sendGA4Event({ category: "login", action: "login_otp_otpvalidated_successful"});
      }
    })));
  };

  const resendOtp = () => {
    setReset(true);
    setApiMsg("");
    handleOtp("");
    sendGA4Event({ category: "login", action: "login_otp_requestresend" });
    dispatch(callResendOtp(createResendOtpPayload(otpId, phoneNumber, (response: any) => {
      if (response?.statusCode === 200) {
        sendGA4Event({ category: "login", action: "login_otp_otpresent" });
        setOtpId(response?.result?.otpId || "");
        setTimer(defaultTimer);
        setCanResendOtp(false);
        startTimer();
        setApiMsg("");
        setReset(false);
        sendGA4Event({ category: "login", action: "resend_otp_success" });
      }
      else {
        setReset(false);
        setApiMsg(response?.message);
        sendGA4Event({ category: "login", action: "resend_otp_failed" });
      }
    })));
  }

  const handleOtpSubmit = () => {
    sendGA4Event({ category: "login", action: "login_otp_otpentered" });
    setApiMsg("");
    if (otp.length === OTP_LENGTH && !loginData?.isVerifyOtpCall) {
      verifyOtp();
      sendGA4Event({ category: "login", action: "verify_otp_click" });
    }
  }

  return (
    <div className="login-otp-ctn">
      <div className="otp-heading mt-24  text-16">
        {getText("OTP_VERIFICATION_HEADING")}
      </div>
      <div className="pls-enter-otp mt-10 mb-24 font-medium  text-12">
        {getText("OTP_VERIFICATION_SUB_HEADING", addSpaceInMsisdn(removeZeroFromStart(phoneNumber)))}
      </div>
      <OTPInput autoFocus isNumberInput length={OTP_LENGTH} className="otpContainer flex justify-between"
        inputClassName={`outline-0 otpInput flex-1 h-[77px]  bg-[#fff] border-[#E6E6E6] border rounded-md text-center ml-8 first:ml-0 
          ${apiMsg ? "border-[1px] border-[#EC1B24] border-solid" : ""}`}
        onChangeOTP={handleOtp} handleSubmit={handleOtpSubmit}
        isReset={isReset}
      />
      {apiMsg &&
        <div className="otp-error-ctn text-12 flex justify-between font-medium text-[#EC1B24] mt-8 ">
          <div className="text-left">  {apiMsg} </div>
          {apiMsg && otpattemptLeft && <div className="text-right text-secondaryBlack font-medium"> {otpattemptLeft} {getText("ATTEMPTS_LEFT")}</div>}
        </div>
      }
      {showResendOtp && <div className="resend-otp-ctn text-[#EC1B24] text-12 font-medium flex justify-between items-center mt-34">
        <div className={`resend-otp-cta ${!canResendOtp ? "opacity-50 pointer-events-none" : ""}`} onClick={resendOtp}>
          {getText("RESEND_OTP_CTA")}
        </div>
        <div className="resend-timer">
          {!canResendOtp && `00:${timer < 10 ? `0${timer}` : timer}`}
        </div>
      </div>
      }
    </div>
  )
}

export default LoginOTP;