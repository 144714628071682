import { createSlice } from "@reduxjs/toolkit";
import { fallbackTranslations, fallbackTranslationCount } from "../../data/constants";
import { clearStore } from "../actions";
import { getSize } from "../../helpers/commonHelpers";

export interface ITranslationStore {
  translations: Record<string, string>;
  count: number;
}

const initialState: ITranslationStore = { translations: fallbackTranslations, count: fallbackTranslationCount };

export const translation = createSlice({
  name: "translationStore",
  initialState,
  reducers: {
    callFetchTranslations: (state, action) => {
    },

    /**
     * Save newly fetched translations
     * @param state 
     * @param action 
     */
    callFetchTranslationsSuccess: (state, action) => {
      if (!state?.translations)
        state = initialState;
      state.translations = action.payload;
      state.count = getSize(state.translations);
    }
  },
  extraReducers: (builder) => {
    builder //clears everything on 401
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
})

export const {
  callFetchTranslations,
  callFetchTranslationsSuccess
} = translation.actions;

export default translation.reducer;