import { useState, useEffect, useRef } from 'react';

interface PollingResult<T> {
  startPolling: () => void;
  stopPolling: () => void;
  isTimeouted: boolean;
}

function usePolling<T>(fn: () => void, interval: number, timeout: number): PollingResult<T> {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [isTimeouted, setIsTimeouted] = useState<boolean>(false);
  const pollingId = useRef<NodeJS.Timeout | null>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isPolling) {
      pollingId.current = setInterval(() => {
        fn();
      }, interval);

      // Setup timeout
      timeoutId.current = setTimeout(() => {
        setIsPolling(false);
        clearInterval(pollingId.current!);
        setIsTimeouted(() => { return true });
      }, timeout);

      // Cleanup function to stop polling and timeout when component unmounts or polling is stopped
      return () => {
        clearInterval(pollingId.current!);
        clearTimeout(timeoutId.current!);
      };
    }
  }, [fn, interval, timeout, isPolling]);

  // Function to start polling
  const startPolling = () => {
    setIsPolling(true);
    setIsTimeouted(false);
  };

  // Function to stop polling
  const stopPolling = () => {
    setIsPolling(false);
    clearInterval(pollingId.current!);
    clearTimeout(timeoutId.current!);
  };

  return { startPolling, stopPolling, isTimeouted };
}

export default usePolling;
