interface IpropsButton {
  handleClick: () => void;
  isDisable?: boolean;
  text: string;
  style?: string
}
const Button = ({ handleClick, isDisable, text, style }: IpropsButton) => {

  const onHandleClick = () => {
    handleClick()
  }

  return (
    <button className={`w-full ${style ? style : 'bg-violet-400 rounded-[2rem] text-white font-medium text-center text-14 py-5'} opacity-${isDisable ? 50 : 200} ${isDisable ? 'pointer-events-none' : ''}`} onClick={onHandleClick}>
      {text}
    </button>
  )
}

export default Button