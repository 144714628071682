import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux";
import ios_guide from "../../assets/icons/ios_aths.png";
import ic_cancel from "../../assets/icons/ic_cancel.svg"
import { useLocation, useNavigate } from "react-router-dom";
import { deleteAuthTokenFromLs } from "../../utils/commonUtils";
import { HOME_PAGE, LOG_OUT, APP_SETTINGS_ROUTE, SUCCESS_V2 } from "../../routes/routeConstants";
import { toggleShowIOSScreenStatus } from "../../helpers/commonHelpers";
import { callLogout } from "../../Redux/slices/appSettingsSlice";
import { useEffect } from "react";
import { dispatchGetIncentive } from "../../helpers/addToHomeHelper";

const RenderIosAthsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const showIosScreen = useSelector((state: RootState) => state.globalStore.showIOSScreen);

  useEffect(() => {
    showIosScreen && dispatchGetIncentive();
  }, [showIosScreen])

  const handleClose = () => {
    toggleShowIOSScreenStatus();
    if (location.pathname === APP_SETTINGS_ROUTE) {
      dispatch(callLogout({
        callback: (res: any) => {
          deleteAuthTokenFromLs()
          navigate(LOG_OUT, { replace: true });
        }
      }));
    } else if (location.pathname === SUCCESS_V2) {
      navigate(HOME_PAGE, { replace: true });
    }
  }

  return (
    <>
      {showIosScreen ? (
        <div className="ios-guide-ctn absolute z-[51] top-0 bottom-0 left-0 right-0 ">
          <div className="absolute right-20 top-20" onClick={handleClose}>
            <img className="" src={ic_cancel} alt="Cancel" />
          </div>
          <div className="main-img">
            <img className="h-[100vh] w-[100vw]" src={ios_guide} alt="iOS Guide" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RenderIosAthsScreen;