
import moment from "moment";
import { store } from "../../../Redux";
import { IGlobalStore, callFetchFavourites } from "../../../Redux/slices/globalSlice";
import { FavouritesType, IBundleFav, IBundleFavMetaData, ICommonFavMetaData, IConfigSliceProps, IFav, IFavConfig, IFavMetaData, IFetchedFav, TFavourites } from "../../../config/commonInterfaces";
import { FAV_DT_FORMAT, FetchedBundleFavMetaDataKeys } from "../../../data/constants";
import { ISuccessLocationData } from "../../../pages/Success";
import * as routes from "../../../routes/routeConstants";
import { getPhoneNumberFromLs, parseMsisdn } from "../../../utils/commonUtils";
import { isObject, orderBy } from "../../../helpers/commonHelpers";

/**
 * Get route of flow from which favs journey was started from
 */
export function getRouteToOriginalFlow(locationData: ISuccessLocationData) {
  if (locationData?.isSendMoneySuccess)
    return routes.SEND_MONEY;
  else if (locationData?.isBuyBundleSuccess)
    return routes.buyBundle.BUNDLE_MENU;
  else if (locationData?.isRechargeSuccess)
    return routes.recharge.RECHARGE;
  else
    return routes.HOME_PAGE;
}

/**
 * Get favourites details like TxnType, Category, MetaData from locationData and basicConfig
 */
export function getFavDetailsFrom(locationData: ISuccessLocationData, basicConfig: IConfigSliceProps["basicConfig"]) {

  const createFavDetailsObj = (locFavMetaData: IBundleFavMetaData | ICommonFavMetaData | Record<string, never>, favsType: TFavourites) => {

    const favMetaData = Object.entries(locFavMetaData)?.map((entry): IFavMetaData => {
      return { key: entry?.[0], value: entry?.[1] }
    });
    return { category: basicConfig?.[favsType]?.category, transactionType: basicConfig?.[favsType]?.transactionType, favMetaData };
  }

  if (locationData?.isSendMoneySuccess) {
    return createFavDetailsObj(locationData?.sendMoneyFavMetaData || {}, FavouritesType.sendMoneyFav);
  }
  else if (locationData?.isRechargeSuccess) {
    return createFavDetailsObj(locationData?.rechargeFavMetaData || {}, FavouritesType.rechargeAirtimeFav);
  }
  else  //if (locationData?.isBuyBundleSuccess) {
    return createFavDetailsObj(locationData?.bundleFavMetaData || {}, FavouritesType.buyBundleFav);

}

export function createFetchFavsPayload(inputFavouritesType: TFavourites, favCategory: IFavConfig["category"], hideLoader: boolean = true) {
  return {
    favouritesType: inputFavouritesType,
    data: {
      msisdn: getPhoneNumberFromLs(),
      category: favCategory
    },
    hideLoader
  };
}

export function createSaveFavPayload(locationData: ISuccessLocationData, basicConfig: IConfigSliceProps["basicConfig"], favName: string, callback: (response: any) => void, errorCallback: (msg: string) => void) {
  const { category: favCategory, transactionType: favTransactionType, favMetaData } = getFavDetailsFrom(locationData, basicConfig);
  const currency = basicConfig?.currency[0];

  return {
    data: {
      data: {
        amount: locationData?.amount,
        //logged in users number
        msisdn: getPhoneNumberFromLs(),
        nickName: favName,
        //receivers number
        referenceNumber: parseMsisdn(locationData?.msisdn),
        unit: currency,
        category: favCategory,
        transactionType: favTransactionType,
        favouriteData: {
          favData: !!favMetaData ? favMetaData : []
        }
      }
    },
    callback,
    errorCallback
  }
}

// sort fetch favs response
export function toSortedFavResponse<T extends IFav | IBundleFav>(responseData: T[]) {
  return orderBy(responseData,
    (element) => moment(element?.createdOn, FAV_DT_FORMAT),
    'desc'
  );
}

export function flattenAndSortFavs(responseData: IFetchedFav[]) {

  const flattenedData = toIFavs(responseData)
  return toSortedFavResponse(flattenedData);
}

/**
 * Convert and typecast input responseData to IFav[]
 * @param responseData data received from fetch favs apis
 * @returns self
 */
function toIFavs(responseData: IFetchedFav[]): IFav[] {
  responseData?.forEach((fetchedFav) => {
    const flattenedFav = fetchedFav as IFav;
    delete flattenedFav?.favouriteValue;

  });
  return responseData as IFav[];
}

/**
 * Convert input responseData to sorted IBundleFav[]
 * @param responseData data received from fetch favs apis
 * @returns self
 */
export function flattenAndSortBundleFavs(responseData: IFetchedFav[]) {
  let unsortedBundleFavResponse = responseData?.map((fetchedFav) => {
    return flattenBundleFavMetaData(fetchedFav);
  });

  return toSortedFavResponse(unsortedBundleFavResponse);
}

/**
 * Flatten input data to fit IBundleFav shape
 * @param responseData data received from fetch favs apis
 * @returns self
 */
export function flattenBundleFavMetaData(fetchedFav: IFetchedFav) {
  const fetchedBundleFav = fetchedFav as IBundleFav;
  const favMetaData = fetchedBundleFav?.favouriteValue?.favData
  delete fetchedBundleFav?.favouriteValue;

  FetchedBundleFavMetaDataKeys.forEach((key: keyof IBundleFavMetaData) => {
    favMetaData?.some(({ key: fetchedKey, value }: IFavMetaData) => {
      if (key === fetchedKey) {
        fetchedBundleFav[key] = value;
        return true;
      }
    });
  })

  return fetchedBundleFav
}

/**
 * Check if entered user and txn details, are already present in favourites store
 * @param locationData - the location data from success page
 * @param favData - fav data in redux store
 **/
export function existsInFavStore(locationData: ISuccessLocationData, favData: IGlobalStore["favouritesData"]): boolean {
  const inputBundleId = locationData?.bundleFavMetaData?.bundleId;
  const inputRecipientNum = parseMsisdn(locationData?.msisdn);

  if (locationData?.isBuyBundleSuccess && inputBundleId && inputRecipientNum) {
    return !!favData?.buyBundleFav?.some((element) =>
      inputBundleId == element?.bundleId && inputRecipientNum == element?.referenceNumber)
  }
  else if ((locationData?.isRechargeSuccess || locationData?.isSendMoneySuccess)
    && locationData?.amount && inputRecipientNum) {
    const thisStore = (locationData?.isRechargeSuccess) ? favData?.rechargeAirtimeFav : favData?.sendMoneyFav;
    return !!thisStore?.some((element) =>
      element?.amount == locationData.amount && inputRecipientNum == element?.referenceNumber)
  }
  else
    return false;
}

/**
 * Fetch favourites to redux store
 * @param source - latest basicConfig reference
 * @param hideLoader - hide loader?
 **/
export function fetchFavourites(source: IConfigSliceProps["basicConfig"], hideLoader: boolean = true) {
  if (!source)
    return;
  if (isObject(FavouritesType)) {
    const favTypesArr = Object.values(FavouritesType);
    !!source && favTypesArr?.length && favTypesArr.forEach((favType) => {
      if (!!!source?.[favType]?.category)
        return;
      const fetchFavsPayload = createFetchFavsPayload(favType, source?.[favType]?.category || "", hideLoader);
      store.dispatch(callFetchFavourites(fetchFavsPayload));
    });
  }
}