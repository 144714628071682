import { ISimpleBalance } from "../config/interface";
import moment from "moment";
import { store } from "../../../Redux";
import { callCheckGSMBalance } from "../../../Redux/slices/balanceSlice";
import { createCheckGSMBalancePayload } from "../config/balancePayloads";
import { getPhoneNumberFromLs } from "../../../utils/commonUtils";
import { sortBy } from "../../../helpers/commonHelpers";

// sort input bundle balance by expiry date time
export function sortISimpleBalanceDetailsByDateTime(
  details: ISimpleBalance["details"],
  objDTFormat?: string
) {
  if (objDTFormat) {
    return sortBy(details, (kv) =>
      moment(kv?.value?.expiry, objDTFormat)
    );
  }
  return sortBy(details, (kv) => moment(kv?.value?.expiry));
}

export function fetchBalance(hideLoader = false, payload = {}) {
  let msisdn = getPhoneNumberFromLs() || "";
  store.dispatch(callCheckGSMBalance(createCheckGSMBalancePayload({ msisdn, ...payload }, hideLoader)));
}