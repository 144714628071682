import airtelIcon from "../assets/icons/airtel_logo_small.svg";
import airtelLogoIcon from "../assets/icons/airtel-logo.svg";
import airtelLogo from "../assets/icons/airtel_icon_x72.svg";
import hburgerIcon from "../assets/icons/ham_menu.svg";
import amWallet from "../assets/icons/am_wallet_icon.svg";
import showBal from "../assets/icons/show_bal.svg";
import hideBal from "../assets/icons/hide_bal.svg";
import forward from "../assets/icons/frwd.svg";
import whiteForward from "../assets/icons/whiteForward.svg";
import helpAndSupport from "../assets/icons/help&support.svg";
import termsAndConditions from "../assets/icons/t&c.svg";
import logout from "../assets/icons/logout.svg";
import aboutUs from "../assets/icons/aboutus.svg";
import phone from "../assets/icons/call.svg";
import address from "../assets/icons/address.svg";
import email from "../assets/icons/email.svg";
import ig from "../assets/icons/ig.svg";
import linkedin from "../assets/icons/linkedin.svg";
import fb from "../assets/icons/fb.svg";
import x from "../assets/icons/x.svg";
import youtube from "../assets/icons/youtube.svg";
import backWhite from "../assets/icons/back_white.svg";
import lockIcn from "../assets/icons/locked.svg";
import amBarredIcn from "../assets/icons/warning-am-icn.svg";
import amPaymentCredit from '../assets/icons/payment-credit.svg';
import amPaymentDebit from '../assets/icons/payment-debit.svg';
import noTransactionsIcn from "../assets/icons/no transaction.svg";
import balRightArrowIcn from "../assets/icons/bal-right-icn.svg";
import balRefreshIcn from "../assets/icons/bal-refresh-icn.svg";
import whiteLeftArrowIcn from "../assets/icons/white_left_arrow.svg";
import packIcn from "../assets/icons/pack_icn.svg";
import closeIcn from "../assets/icons/ic_close.svg";
import offerIcn from "../assets/icons/offer.svg";
import successfulIcn from "../assets/icons/successful.svg";
import redXIcn from "../assets/icons/red-x-icon.svg";
import downIcn from "../assets/icons/down-icon.svg";
import airtelIcn from "../assets/icons/airtel.svg";
import safaricomIcn from "../assets/icons/safaricom.svg";
import medishop_icon from "../assets/icons/medishop_icon.svg";
import filterIcn from "../assets/icons/filter.svg";
import searchIcn from "../assets/icons/search.svg";
import no_file_icon from "../assets/icons/no-file.svg";
import backIcn from "../assets/icons/back_black.svg";
import mpesa from "../assets/icons/mpesa.png";
import info from "../assets/icons/info.svg";
import edit from "../assets/icons/edit.svg";
import ic_ban_red from "../assets/icons/ic_ban_red.svg";
import blockIcon from "../assets/icons/ic_ban.svg";
import exclamation from "../assets/icons/ic_xslamation_icon.svg";
import clock from "../assets/icons/ic_clock.svg";
import shareIcn from "../assets/icons/share-icn.svg";
import refreshIcon from "../assets/icons/refresh_reload.svg";
import airtimeWalletIcn from "../assets/icons/airtime_wallet.svg";
import oops from "../assets/icons/oops.svg";
import checkIcon from "../assets/icons/ic_check.svg";
import mobileVerify from "../assets/icons/mobile_verify.svg";
import clock_time from "../assets/icons/clock_time.svg";
import redCrossIcon from "../assets/icons/ic-red-close.svg";
import mobileSuccessTick from "../assets/icons/mobileSuccessTick.svg";
import mobileFailedTick from "../assets/icons/mobileFailedTick.svg";
import timeoutIcon from "../assets/icons/ic-timeout.svg";
import accountLockedIcn from "../assets/icons/acc_locked_icn.svg";
import clearIcn from "../assets/icons/clear-icn.svg";
import chromeIcn from "../assets/icons/chrome_icn.svg";
import safariIcn from "../assets/icons/safari_icn.svg";
import operaIcn from "../assets/icons/opera_icn.svg";
import samsungIcn from "../assets/icons/samsung_icn.svg";
import miIcn from "../assets/icons/mi_icn.svg";
import pheonixIcn from "../assets/icons/pheonix_icn.svg";
import incompatible_Icn from "../assets/icons/incompatible.png";
import refreshEmpty from "../assets/icons/refresh-empty.svg";
import securityQuestionIcn from "../assets/icons/security_question_icn.svg";
import tip_Icn from "../assets/icons/tips_icn.svg";
import dot_Icn from "../assets/icons/dot_icn.svg";
import crossIcn from "../assets/icons/ic_cancel.svg";
import languageIcn from "../assets/icons/ic_language.svg";
import languageWhiteIcn from "../assets/icons/ic_language_white.svg";
import greenCheckIcn from "../assets/icons/ic_green_check.svg";
import greyCheckIcn from "../assets/icons/ic_grey_check.svg";
import errorIcn from "../assets/icons/error.svg";
import airtel_lite_icn from "../assets/icons/airtel_lite_icn.svg";
import changePin from "../assets/icons/change_pin.svg";
import manageQuestion from "../assets/icons/manageQuestion.svg";
import warningIcn from "../assets/icons/am_warning.svg";
import forgotPinIcn from "../assets/icons/forgot-pin.svg";
import airtelShop from "../assets/icons/airtel_shop.svg";
import callCC from "../assets/icons/call-cc.svg";
import refreshIconWithBorder from "../assets/icons/refresh_icon_with_border.svg";
import KE from "../assets/icons/flag/KE.png";
import TZ from "../assets/icons/flag/TZ.png";
import MW from "../assets/icons/flag/MW.png";
import ZM from "../assets/icons/flag/ZM.png";
import GA from "../assets/icons/flag/GA.png";
import CD from "../assets/icons/flag/CD.png";
import UG from "../assets/icons/flag/UG.png";
import exclamationMark from "../assets/icons/exclamation_mark.svg";
import airtel_home_view_icon from "../assets/icons/airtel_home_view_icon.png";
import AccordianUpIcn from "../assets/icons/dropdownUp.svg";
import AccordianDownIcn from "../assets/icons/dropdownDown.svg";
import faqIcn from "../assets/icons/ic_faq.svg";
import loanConcentIcn from "../assets/icons/loan_concent.png"
import voiceLoanCatIcn from "../assets/icons/voice_loan_cat.png";
import dataLoanCatIcn from "../assets/icons/data_loan_cat.png";
import airtimeLoanCatIcn from "../assets/icons/airtime_loan_cat.png";
import comboandmoreLoanCatIcn from "../assets/icons/como_and_more.svg";
import loanCardBgIcn from "../assets/icons/loan_card_bg_icn.svg";
import loanIcn from "../assets/icons/ic_loan.svg";
import noLoanTransactionsIcn from "../assets/icons/no_loan_transaction.svg";

import webPageMobileIcn from "../assets/icons/web_page_phone_icn1.png";
import webPageSlide1Icn from "../assets/icons/web_page_slide1_icn1.svg";
import webPageSlide2Icn from "../assets/icons/web_page_slide2_icn1.svg";
import webPageSlide3Icn from "../assets/icons/web_page_slide3_icn1.svg";
import webPageSlide4Icn from "../assets/icons/web_page_slide4_icn1.svg";
import webPageSlide5Icn from "../assets/icons/web_page_slide5_icn1.svg";
import webPageBgIcn from "../assets/icons/web_page_bg_icn.svg";
import airtelQRKE from "../assets/icons/QR-KE.svg";
import airtelQRCD from "../assets/icons/QR-CD.svg";
import airtelQRGA from "../assets/icons/QR-GA.svg";
import airtelQRMW from "../assets/icons/QR-MW.svg";
import airtelQRUG from "../assets/icons/QR-UG.svg";
import airtelQRTZ from "../assets/icons/QR-TZ.svg";
import airtelQRZM from "../assets/icons/QR-ZM.svg";
import no_internet_icn from "../assets/icons/no_internet_icn.png";

const icons = {
    "airtelIcon": airtelIcon,
    "airtelLogo": airtelLogo,
    "hburgerIcon": hburgerIcon,
    "airtel": airtelLogoIcon,
    "amWallet": amWallet,
    "showBal": showBal,
    "hideBal": hideBal,
    "forward": forward,
    "phone": phone,
    "email": email,
    "backWhite": backWhite,
    "address": address,
    "ig": ig,
    "ln": linkedin,
    "fb": fb,
    "x": x,
    "yt": youtube,
    "lock": lockIcn,
    "amBarred": amBarredIcn,
    "amDebitIcon": amPaymentDebit,
    "amCreditIcon": amPaymentCredit,
    "noTransactionsIcn": noTransactionsIcn,
    "balRightArrow": balRightArrowIcn,
    "balRefresh": balRefreshIcn,
    "whiteleftArrow": whiteLeftArrowIcn,
    "packIcn": packIcn,
    "closeIcn": closeIcn,
    "offerIcn": offerIcn,
    "successfullIcn": successfulIcn,
    "redXIcn": redXIcn,
    "downIcn": downIcn,
    "operator/airtel.png": airtelIcn,
    "operator/safaricom.png": safaricomIcn,
    'medishop_icon': medishop_icon,
    "filterIcn": filterIcn,
    "searchIcn": searchIcn,
    'no_file_icon': no_file_icon,
    'backIcn': backIcn,
    "mpesa": mpesa,
    "info": info,
    "edit": edit,
    'ic_ban_red': ic_ban_red,
    'blockIcon': blockIcon,
    'exclamation': exclamation,
    'clock': clock,
    'shareIcn': shareIcn,
    'refreshIcon': refreshIcon,
    'airtimeWallet': airtimeWalletIcn,
    'oops': oops,
    'checkIcon': checkIcon,
    'mobileVerify': mobileVerify,
    'clock_time': clock_time,
    'redCloseIcon': redCrossIcon,
    'mobileSuccessTick': mobileSuccessTick,
    'mobileFailedTick': mobileFailedTick,
    'timeoutIcon': timeoutIcon,
    'accountLockedIcn': accountLockedIcn,
    'clearIcn': clearIcn,
    'refreshEmpty': refreshEmpty,
    'crossIcn': crossIcn,
    'languageWhite': languageWhiteIcn,
    'greenCheck': greenCheckIcn,
    'greyCheck': greyCheckIcn,
    'errorIcn': errorIcn,
    'airtelShopIcn': airtelShop,
    'callCCIcn': callCC,
    'refreshIconWithBorder': refreshIconWithBorder,
    'exclamationMark': exclamationMark,
    'loanConfirmIcn': loanConcentIcn,
    "loanCardBgIcn": loanCardBgIcn,
    "loanIcn": loanIcn,
    "VOICELoanCatIcn": voiceLoanCatIcn,
    "DATALoanCatIcn": dataLoanCatIcn,
    "AIRTIMELoanCatIcn": airtimeLoanCatIcn,
    "COMBO&MORELoanCatIcn": comboandmoreLoanCatIcn,
    "noLoanTransactionsIcn": noLoanTransactionsIcn,
}

export const browserIcns = {
    'chromIcn': chromeIcn,
    'safariIcn': safariIcn,
    'operaIcn': operaIcn,
    'samsungIcn': samsungIcn,
    'miIcn': miIcn,
    'pheonixIcn': pheonixIcn
}

export const flag = {
    'KE': KE,
    'TZ': TZ,
    'GA': GA,
    'ZM': ZM,
    'MW': MW,
    'CD': CD,
    'UG': UG
}

export const appSettingsIcns = {
    'changePin': changePin,
    "helpAndSupport": helpAndSupport,
    "termsAndConditions": termsAndConditions,
    "aboutUs": aboutUs,
    "logout": logout,
    "whiteForward": whiteForward,
    "manageQuestion": manageQuestion,
    "forgotPin": forgotPinIcn,
    "language": languageIcn,
    "faq": faqIcn
}

export const AccordianIcn = {
    "upIcn": AccordianUpIcn,
    "downIcn": AccordianDownIcn
}

export const incompatibleIcn = incompatible_Icn;
export const securityQuestionsIcn = securityQuestionIcn;
export const tipIcn = tip_Icn;
export const dotIcn = dot_Icn;
export const airtelLiteIcn = airtel_lite_icn;
export const amWarningIcn = warningIcn;
export const airtelHomeViewIcon = airtel_home_view_icon;
export const noInternetIcn = no_internet_icn


export default icons;

export const webLandingPageIcns = {
    'webPageMobileIcn': webPageMobileIcn,
    "webPageSlide1Icn": webPageSlide1Icn,
    "webPageSlide2Icn": webPageSlide2Icn,
    "webPageSlide3Icn": webPageSlide3Icn,
    "webPageSlide4Icn": webPageSlide4Icn,
    "webPageSlide5Icn": webPageSlide5Icn,
    "webPageBgIcn": webPageBgIcn,
    "airtelQRKE": airtelQRKE,
    "airtelQRGA": airtelQRGA,
    "airtelQRMW": airtelQRMW,
    "airtelQRCD": airtelQRCD,
    "airtelQRTZ": airtelQRTZ,
    "airtelQRZM": airtelQRZM,
    "airtelQRUG": airtelQRUG,
}