import Divider from "../Divider";
import Modal from "../Modal/Modal";
import { NO_INTERNET_TEXT, RETRY } from "../../data/constants";
import { noInternetIcn } from "../../config/icons";
import { getText } from "../../helpers/translateHelper";

interface IPropsNoInternetModal {
  handleClose?: () => void;
  handleRefresh?: () => void
}

const NoInternetModal = ({ handleClose, handleRefresh }: IPropsNoInternetModal) => {

  const handleRetry = () => {
    handleRefresh?.()
  }

  const handleCloseModal = () => {
    handleClose?.()
  }
  return (
    <Modal
      isOpen={true}
      onClose={handleCloseModal}
    >
      <div className="am-pin-set-error-popup-main w-[100vw] h-[300px] top-[calc(50%-150px)] fixed">
        <div className="am-pin-set-wrapper relative bg-white mx-30 flex flex-col items-center rounded">
          <div className="flex flex-col gap-20 p-10 items-center">
            <div className="warning-icn mt-20">
              <img className="" src={noInternetIcn} alt="warning-icn" />
            </div>
            <div className="px-25 text-center">
              {getText(NO_INTERNET_TEXT.NO_INTERNET)}
            </div>
          </div>
          <Divider />
          <div className="flex justify-center items-center w-full pb-15" onClick={handleRetry}>
            {getText(RETRY)}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default NoInternetModal;