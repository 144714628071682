import { store } from "../Redux";
import { callGetIncentive } from "../Redux/slices/homeSlice";
import { ACCEPTED, DISMISSED, EVENT_TYPE } from "../data/constants";
import { sendGA4Event } from "../services/ga4";
import { checkIos } from "../utils/commonUtils";
import { storeAthsEvent, toggleShowIOSScreenStatus } from "./commonHelpers";
import { APP_SETTINGS_ROUTE, HOME_PAGE, SUCCESS_V2 } from "../routes/routeConstants";

export const dispatchGetIncentive = (addToHomeCallback = () => { }) => {
  store.dispatch(callGetIncentive({ data: {}, hideLoader: true, callback: addToHomeCallback, errorCallback: addToHomeCallback }));
}

export const handleAddToHomeScreen = (from: string, callback = () => { }) => {
  const event = store.getState()?.globalStore?.athsEvent || {};
  if (!checkIos()) {
    try {
      if (event.prompt) event.prompt();
      event.userChoice?.then((choiceResult: any) => {
        if (choiceResult.outcome === ACCEPTED) {
          dispatchGetIncentive(callback)
          storeAthsEvent({});
          handleEventCall(from, EVENT_TYPE.SUCCESS)
        } else if (choiceResult.outcome === DISMISSED) {
          handleEventCall(from, EVENT_TYPE.CANCELLED)
          callback?.();
        }
      });
    } catch (e) {
      console.log("athsPopUp , expection : " + e);
      handleEventCall(from, EVENT_TYPE.FAILED)
      callback?.();
    }
  } else {
    toggleShowIOSScreenStatus(true);
  }
};

const handleEventCall = (from: string, eventType: string) => {
  if (from === APP_SETTINGS_ROUTE) {
    sendGA4Event({ category: from, action: `savephone_exitpage_${eventType}` })
  } else if (from === HOME_PAGE) {
    sendGA4Event({ category: "Home", action: `savephone_homepage_${eventType}` })
  } else if (from === SUCCESS_V2) {
    sendGA4Event({ category: "Success", action: `savephone_trxpage_${eventType}` })
  }
}