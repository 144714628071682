import { useState, useEffect } from 'react';
import { store } from '../Redux';
import { setOfflinePopupFlag, setOnline } from '../Redux/slices/globalSlice';
import { sendGA4Event } from '../services/ga4';

export function useOnlineStatus() {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
      store.dispatch(setOnline(true));
      sendGA4Event({ category: "home", action: `user_online` });
    }
    function handleOffline() {
      setIsOnline(false);
      store.dispatch(setOnline(false));
      store.dispatch(setOfflinePopupFlag(false));
      sendGA4Event({ category: "home", action: `user_offline` });
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return isOnline;
}
