import { ReactHTMLElement, ReactNode, useEffect, useState } from "react";
import { getPercentageBasedOnLength } from "../../utils/commonUtils";
import './index.css'
interface IPropsCarousel {
  items: any;
  itemRenderer: (item: any, index: number) => ReactNode,
  isBottomButtonRequired?: boolean,
  isAutomaticSlideChange?: boolean,
  slideChangeDuration?: number,
  sliderContainerStyle?: string
}
const Carousel = ({ items,
  itemRenderer,
  isBottomButtonRequired,
  isAutomaticSlideChange,
  slideChangeDuration = 5000,
  sliderContainerStyle
}: IPropsCarousel) => {

  const [activeTab, setActiveTab] = useState<number>(0);
  const transformPercentage = getPercentageBasedOnLength(items?.length);

  const addTransformToActiveSlide = (no: number) => {
    const slider: HTMLInputElement = document.querySelector('.slider') as HTMLInputElement;
    slider.style.transform = `translateX(-${no * transformPercentage}%)`;
  }

  const handleTabChange = (item: any) => {
    setActiveTab(item)
    addTransformToActiveSlide(item)
  }

  useEffect(() => {
    if (isAutomaticSlideChange) {
      const intervalId = setInterval(() => {
        if (activeTab < items.length - 1) {
          setActiveTab(activeTab + 1)
          addTransformToActiveSlide(activeTab + 1)
        } else {
          setActiveTab(0)
          addTransformToActiveSlide(0)
        }

      }, slideChangeDuration);
      return () => clearInterval(intervalId)
    }
  }, [activeTab]);

  const isActiveSlide = (index: number) => {
    return index === activeTab
  }

  return (
    <>
      <div id="default-carousel" className="slider-wrap relative rounded-[10px]" data-carousel="slide">
        <div className={`slider ${sliderContainerStyle ?? 'h-80 rounded-lg'}`}>
          {
            items?.map((item: any, index: number) => {
              return <div key={`craousel_render_item_${index}`} className={`slider-item min-w-[${transformPercentage}%]`}> {itemRenderer(item, index)}</div>
            })
          }
        </div>
        {isBottomButtonRequired ? <div className="absolute z-30 flex -translate-x-1/2  left-1/2 space-x-[4px] rtl:space-x-reverse pt-5 bottom-0">
          {
            items.length > 1 && items.map((_: any, index: number) => {
              const isActive = isActiveSlide(index)
              return (
                <button key={`craousel_button_${index}`} type="button" className={`h-[3px] rounded-full bg-white opacity-[${isActive ? 100 : 25}]`} aria-current="true"
                  aria-label={`Slide ${index}`} data-carousel-slide-to={index} onClick={() => handleTabChange(index)}
                  style={{ opacity: isActive ? 1 : 0.5, background: isActive ? '#0000FF' : '#808080', width: isActive ? '16px' : '8px' }}
                ></button>
              )
            })
          }
        </div> : null}
      </div >

    </>
  )
}

export default Carousel 