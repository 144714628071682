import { NO_INTERNET_TEXT } from "../../data/constants";
import { getText } from "../../helpers/translateHelper";

const OfflineStripComponent = () => {
  return <div className="w-full bg-[#601EEE] text-center text-[#fff]  py-4 text-12 z-[1000] relative">
    <span className="font-[500] text-13">
      {getText(NO_INTERNET_TEXT.OFFLINE_MODE_HEADER_TEXT)}
    </span>
  </div>
}

export default OfflineStripComponent