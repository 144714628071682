import axios from "axios";
import { store } from "../Redux";
import { BASE_API_URL } from "../config/endpoints";
import { SOME_ERR, fallbackTranslations } from "../data/constants";
import {
  checkIsOnline,
  getLoaderRunningStatus,
  setApiErrorStatus,
  startLoader,
  stopLoader
} from "../helpers/commonHelpers";
import { deleteAuthTokenFromLs, getAuthTokenFromLs, getIsGuestUser, getPhoneNumberFromLs, getOpcoDefaultLang, getUserLang } from "../utils/commonUtils";
import { clearStore } from "../Redux/actions";
import packageJson from "../../package.json";
import { getOS } from "../utils/browserDetails";

const defaultOptions = {
  baseURL: `${BASE_API_URL}`,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "channel": "APP",
    "application": "DEFAULT",
    "buildNo": "1",
    "deviceName": "web",
    "OPCO": process.env.REACT_APP_OPCO,
    "x-country": process.env.REACT_APP_X_COUNTRY,
    "Accept-Language": getOpcoDefaultLang(),
    "x-fe-version": packageJson?.version || "",
    "x-device-type": getOS()?.name || ""
  },
};

const apiErrorCodes = [500, 502, 503];

const api = axios.create(defaultOptions);
declare module 'axios' {
  export interface AxiosRequestConfig {
    hideLoader?: boolean;
  }
}

api.interceptors.request.use((config: any) => {
  if (!checkIsOnline()) {
    return
  }
  setApiErrorStatus(false);
  let isLoading = getLoaderRunningStatus();
  if (!isLoading && checkIsOnline() && !config.hideLoader) startLoader();
  if (getAuthTokenFromLs()) {
    config.headers["Authorization"] = `Bearer ${getAuthTokenFromLs()}`;
  }
  if (getPhoneNumberFromLs())
    config.headers["msisdn"] = `${getPhoneNumberFromLs()}`;
  if (getUserLang())
    config.headers["Accept-Language"] = `${getUserLang()}`;
  return config
});

api.interceptors.response.use(
  (response) => {
    let isLoading = getLoaderRunningStatus();
    if (isLoading)
      stopLoader();
    return response;
  },
  function (error) {
    if (apiErrorCodes.includes(error.request.status) && (error.request.responseURL.includes("launch-config") || error.request.responseURL.includes("translation") || error.request.responseURL.includes("features-available"))) {
      setApiErrorStatus(true, error?.request?.responseURL);
    }
    if (error?.response?.status === 401) {
      deleteAuthTokenFromLs();
      !getIsGuestUser() && store.dispatch(clearStore());
    }
    setTimeout(() => {
      stopLoader();
    }, 500);
    if (error.code !== "ERR_NETWORK") {
      if (error?.response?.data && !error.response.data?.message)
        error.response.data["message"] = fallbackTranslations[SOME_ERR];
      return (error.response);
    }
  }
);

export default api;
