import Carousel from "../../../components/Carousel/Carousel"
import { headings } from "../../../data/constants";
import { webLandingPageIcns } from "../../../config/icons"
import { getText } from "../../../helpers/translateHelper"
import { LANDING_PAGE_SLIDER_ARRAY } from "../constants";

const SliderComponent = () => {

  const { LANDING_PAGE_SLIDER_HEADER, LANDING_PAGE_SLIDER_SUMMARY } = headings;

  return (<div className="flex flex-col bg-white border border-[#E8E8E8] rounded-[1.5rem] p-15">
    <Carousel
      items={LANDING_PAGE_SLIDER_ARRAY}
      itemRenderer={(item: number) => {
        return <div className="grid grid-cols-3 w-[645px]">
          <div className="bg-cover ">
            <img src={webLandingPageIcns[`webPageSlide${item}Icn` as keyof typeof webLandingPageIcns]} alt="" />
          </div>
          <div className="flex flex-col justify-start gap-10 py-15 pl-15 text-start col-span-2">
            <div className="self-start text-[18px] font-bold">
              {getText(`${LANDING_PAGE_SLIDER_HEADER}${item}`)}
            </div>
            <div className="self-start text-10 p-0">
              {getText(`${LANDING_PAGE_SLIDER_SUMMARY}${item}`)}
            </div>
          </div>
        </div>
      }}
      isBottomButtonRequired={true}
      isAutomaticSlideChange={true}
      sliderContainerStyle='min-h-[180px]'
    />
  </div>)
}

export default SliderComponent