import { FC } from "react";
import Modal from "../Modal/Modal";
import icons, { airtelHomeViewIcon, airtelLiteIcn } from "../../config/icons";
import { getText } from "../../helpers/translateHelper";
import { handleAddToHomeScreen } from "../../helpers/addToHomeHelper";
import { useDispatch, useSelector } from "react-redux";
import { updateMayBeLater } from "../../Redux/slices/globalSlice";
import { RootState } from "../../Redux";
import { updateUserConfig } from "../../helpers/userInfoHelper";
import { APP_SETTINGS_ROUTE, HOME_PAGE, SUCCESS_V2 } from "../../routes/routeConstants";
import { sendGA4Event } from "../../services/ga4";
import { showAddToHomePopUp } from "../../utils/commonUtils";
import { DONT_ASK_AGAIN_TEXT, EVENT_TYPE, FIRST_TIME_USER_POP_UP_TITLE, MAYBE_LATER_TEXT, REPEATED_USER_POP_UP_TITLE } from "../../data/constants";
import { updateAddtoHomePopToggle } from "../../helpers/commonHelpers";

interface Props {
  isOpen: boolean;
  onClose?: (...args: any[]) => void;
  from: string;
}

const AddToHomePopup: FC<Props> = ({ isOpen, onClose, from }) => {
  const dispatch = useDispatch();
  const firstTimeUser = useSelector((state: RootState) => state.home?.userInfo?.userConfig?.firstLogin);

  const handleClose = () => {
    dispatchTohidePopUp();
    onClose?.(true);
  }

  const dispatchTohidePopUp = () => {
    updateAddtoHomePopToggle(false);
  }

  const handleAdd = () => {
    dispatchTohidePopUp();
    handleEventCall(from, EVENT_TYPE.ADDNOW);
    handleAddToHomeScreen(from, onClose);
  }

  const handleLater = () => {
    if (!firstTimeUser) {
      handleEventCall(from, EVENT_TYPE.DONTASK);
      updateUserConfig({ saveToHomeDNDEnabled: true }, handleClose);
    } else {
      dispatch(updateMayBeLater({ mayBeLater: true }));
      handleEventCall(from, EVENT_TYPE.LATER);
      handleClose();
    }
  }

  const handleEventCall = (from: string, eventType: string) => {
    if (from === SUCCESS_V2) {
      sendGA4Event({ category: from, action: `savephone_trxpage_${eventType}_clicked` });
    } else if (from === APP_SETTINGS_ROUTE) {
      sendGA4Event({ category: from, action: `savephone_exitpage_${eventType}_clicked` });
    } else if (from === HOME_PAGE) {
      sendGA4Event({ category: from, action: `savephone_homepage_clicked` });
    }
  }

  return (
    <Modal
      isOpen={isOpen && showAddToHomePopUp()}
      onClose={() => { }}
    >
      <div className="transaction-detail-modal bg-white py-16 px-20 w-[100vw]">
        <div className="close-icn absolute right-8 top-8" onClick={handleClose}>
          <img className="w-24 h-24" src={icons.closeIcn} alt="close-icn" />
        </div>
        <div className="main-ctn flex mt-[20px]">
          <div className="relative">
            <img className="w-75" src={airtelLiteIcn} alt="am-barred" />
          </div>
          <div>
            <div className="main-heading font-semibold ml-10 text-16 text-[#242224] w-[90%]">
              {firstTimeUser ? getText(FIRST_TIME_USER_POP_UP_TITLE) : getText(REPEATED_USER_POP_UP_TITLE)}
            </div>
            <div className="mt-[15px] ml-[15px]">
              <img className="w-[100%] h-[110px] rounded" src={airtelHomeViewIcon} alt="am-barred" />
            </div>
          </div>
        </div>
        <div className="cta-ctn flex justify-between mt-18 ml-60">
          <div className="py-10 px-20 text-gray-400 font-bold text-14 text-center" onClick={handleLater}>{firstTimeUser ? getText(MAYBE_LATER_TEXT) : getText(DONT_ASK_AGAIN_TEXT)}</div>
          <div className="py-10 px-20 bg-[#0d81f8] text-white font-bold rounded text-14 text-center" onClick={handleAdd} id="add-to-homescreen">{getText("ADD_NOW")}</div>
        </div>
      </div>
    </Modal>
  )
}

export default AddToHomePopup;