import Modal from "../../../components/Modal/Modal";
import icons from "../../../config/icons";
import { getText } from "../../../helpers/translateHelper";
import { ACCOUNT_LOCKED_HEADING, ACCOUNT_LOCKED_TEXT } from "../constants";

interface Porps {
  closeSecureSms: () => void;
}

const SecureSmsLockedModal = ({ closeSecureSms }: Porps) => {
  return (
    <Modal
      isOpen={true}
      onClose={() => { }}
    >
      <div className="acoount-locked-modal-main  bg-white w-[100vw] rounded-t-[20px] p-26 flex flex-col justify-center text-[#232530]">
        <div className="main-heading flex justify-between">
          <div className="heading-main text-18 font-bold">
            {getText(ACCOUNT_LOCKED_HEADING)}
          </div>
          <div className="close-icon" onClick={closeSecureSms}>
            <img src={icons.redCloseIcon} alt="red-close-icn" />
          </div>
        </div>
        <div className="icon-ctn mt-32 flex justify-center">
          <img src={icons.accountLockedIcn} alt="icn" />
        </div>
        <div className="description-ctn mt-26 text-center" dangerouslySetInnerHTML={{ __html: getText(ACCOUNT_LOCKED_TEXT) }} />
        <div className="ok-cta rounded-[4px] bg-[#293F55] text-white py-12 text-center font-bold text-18 mt-24" onClick={closeSecureSms}>
          {getText("OK")}
        </div>
      </div>
    </Modal>
  )
};

export default SecureSmsLockedModal;