import { useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import icons from "../../../config/icons";
import { getText } from "../../../helpers/translateHelper";
import { VERIFICATION_FAILED_TEXT } from "../constants";
import { sendGA4Event } from "../../../services/ga4";
interface Props {
  isModalOpen: boolean;
  closeSecureSms: () => void;
  parentFunc: () => void;
  messageText: string;
  ctaKey: string;
}
const SecureSmsFailedModal = ({ closeSecureSms, parentFunc, messageText, ctaKey }: Props) => {
  useEffect(()=>{
    sendGA4Event({category: "secure-sms", action: "login_securesms_smscheck_fail"});
  }, [])
  
  return (
    <>
      <Modal isOpen={true} onClose={() => { }}>
        <div className="text-center fixed bottom-[0]  left-0   w-[100vw] min-h-[200px] rounded-[20px] rounded-br-none rounded-bl-none bg-white flex flex-col items-center py-[40px] px-[25px]">
          <div className="flex justify-between w-[100%]">
            <h4 className="text-18 font-bold ">
              {getText(VERIFICATION_FAILED_TEXT)}
            </h4>
            <img
              src={icons.redCloseIcon}
              alt="icn"
              onClick={closeSecureSms}
            />
          </div>
          <img
            className="inline-block my-[30px]"
            src={icons.mobileFailedTick}
            alt={icons.mobileFailedTick}
          />
          <p className="text-16 text-[#4A4343]" dangerouslySetInnerHTML={{ __html: messageText }} />
          <button className="mt-[55px] bg-[#293F55]
         text-[#fff] text-18 font-bold text-center
          w-full py-[11px] rounded-[4px] border-none" onClick={parentFunc}>
            {getText(ctaKey)}
          </button>

        </div>
      </Modal>
    </>
  );
}

export default SecureSmsFailedModal;